import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Collapse,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    Box,
    TextField,
    Typography,
    CircularProgress,
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

import JsonDiffView from './json_diff_view';

interface PreviewChangesModalProps {
    open: boolean;
    initialData: any[];
    editedData: any[];
    selectedRows: Set<string>;
    onClose: () => void;
    onConfirm: () => void;
    propertiesToCheck: Set<string>;
    comparisonKey: string;
    ignoreMsUpdates: boolean;
    useTransaction: boolean;
    changes: any[];
}

interface PriceEstimateUpdate {
    old_estimate: number;
    new_estimate: number;
    is_explicit: boolean;
    row_number?: number;
}

interface PriceDiffs {
    [key: string]: PriceEstimateUpdate;
}

const PreviewChangesModal: React.FC<PreviewChangesModalProps> = ({
    open,
    initialData,
    editedData,
    selectedRows,
    onClose,
    onConfirm,
    propertiesToCheck,
    changes,
    comparisonKey = 'market_ticker',
    ignoreMsUpdates = false,
    useTransaction = false,
}) => {
    const [pricePreview, setPricePreview] = useState<PriceDiffs | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [debugExpanded, setDebugExpanded] = useState(false);

    useEffect(() => {
        const fetchPricePreview = async () => {
            try {
                const priceDiffs: PriceDiffs = {};

                if (changes.length === 0) return;

                setIsLoading(true);
                setError(null);

                // TODO: this should flag whether it's a synthetic change or not.
                // Alternatively, we just remove the synthetic branch all together.
                changes.forEach((change: { price_estimate?: number;[key: string]: any }) => {
                    console.log("change:", change);
                    if ("kt_price_estimate" in change && change["kt_price_estimate"] !== undefined) {
                        const ticker = change[comparisonKey];
                        const initialItem = initialData.find(item => item[comparisonKey] === ticker);
                        if (initialItem && "kt_price_estimate" in initialItem && initialItem.kt_price_estimate !== undefined) {
                            priceDiffs[ticker] = {
                                old_estimate: initialItem.kt_price_estimate,
                                new_estimate: change.kt_price_estimate,
                                is_explicit: true
                            };
                        }
                    } else if ("price_estimate" in change && change["price_estimate"] !== undefined) {
                        const ticker = change[comparisonKey];
                        const initialItem = initialData.find(item => item[comparisonKey] === ticker);
                        if (initialItem && "price_estimate" in initialItem && initialItem.price_estimate !== undefined) {
                            priceDiffs[ticker] = {
                                old_estimate: initialItem.price_estimate,
                                new_estimate: change.price_estimate,
                                is_explicit: true
                            };
                        }
                    }
                });

                console.log("price diffs:", priceDiffs);

                const priceChangesParam = encodeURIComponent(JSON.stringify(priceDiffs));
                const baseUrl = process.env.REACT_APP_API_BASE_URL;
                const url = `${baseUrl}/preview-price-changes?price_changes=${priceChangesParam}`;

                const response = await axios.get(url);
                console.log("preview-changes", response.data.updates);
                setPricePreview(response.data.updates);
            } catch (error) {
                console.error("Error previewing price changes:", error);
                setError("Failed to fetch price change preview. Please try again.");
            } finally {
                setIsLoading(false);
            }
        };

        fetchPricePreview();
    }, [initialData, comparisonKey]);

    return (
        <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
            <DialogTitle>Preview Changes</DialogTitle>
            <DialogContent>
                <DialogContentText mb={2}>
                    Please review the following changes before submitting:
                </DialogContentText>
                <Box sx={{ maxHeight: '60vh', overflow: 'auto', pr: 2 }}>
                    {changes.length > 0 ? (
                        <>
                            <Typography variant="h6" gutterBottom>Regular Changes:</Typography>
                            {changes.map((change) => {
                                const ticker = change[comparisonKey];
                                let initialItem = initialData.find(item => item[comparisonKey] === ticker) || {};
                                let editedItem = editedData.find(item => item[comparisonKey] === ticker) || {};
                                return (
                                    <Box key={ticker} mb={3}>
                                        <Typography variant="subtitle1">{ticker}:</Typography>
                                        <JsonDiffView
                                            initialValue={initialItem}
                                            newValue={editedItem}
                                            propertiesToCheck={propertiesToCheck}
                                            comparisonKey={comparisonKey}
                                        />
                                    </Box>
                                );
                            })}

                            {pricePreview && Object.keys(pricePreview).length > 0 && (
                                <Box mt={4}>
                                    <Typography variant="h6" gutterBottom>Price Change Previews: (Does not consider ignore mode or ignore toggle)</Typography>
                                    <TableContainer component={Paper}>
                                        <Table size="small" aria-label="price change table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell><Typography variant="subtitle2">Ticker</Typography></TableCell>
                                                    <TableCell><Typography variant="subtitle2">Change</Typography></TableCell>
                                                    <TableCell><Typography variant="subtitle2">Update Type</Typography></TableCell>
                                                </TableRow>
                                            </TableHead>

                                            <TableBody>
                                                {Object.entries(pricePreview)
                                                    .sort(([, a], [, b]) => (a.row_number ?? 0) - (b.row_number ?? 0))
                                                    .map(([ticker, priceChange]) => {
                                                        const priceDiff = priceChange.new_estimate - priceChange.old_estimate;
                                                        let diffColor = "textPrimary";

                                                        if (priceDiff > 50) diffColor = "error";
                                                        else if (priceDiff > 20) diffColor = "secondary";
                                                        else if (priceDiff > 0) diffColor = "success";

                                                        const formatNumber = (num: number) => {
                                                            return num.toFixed(2).padStart(5, '0');
                                                        };

                                                        return (
                                                            <TableRow key={ticker}>
                                                                <TableCell component="th" scope="row" style={{ backgroundColor: 'inherit', padding: '4px 8px' }}>
                                                                    <Typography variant="body2">{ticker}</Typography>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Typography variant="body2" color={diffColor}>
                                                                        {`${formatNumber(priceChange.old_estimate)} -> ${formatNumber(priceChange.new_estimate)} (${priceDiff > 0 ? '+' : ''}${formatNumber(priceDiff)})`}
                                                                    </Typography>
                                                                </TableCell>
                                                                <TableCell>
                                                                    {priceChange.is_explicit && (
                                                                        <Typography
                                                                            variant="caption"
                                                                            style={{
                                                                                color: '#ff6f61',
                                                                                fontWeight: 'bold',
                                                                            }}
                                                                        >
                                                                            Explicit Change
                                                                        </Typography>
                                                                    )}
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    })}
                                            </TableBody>


                                        </Table>
                                    </TableContainer>
                                </Box>
                            )}

                        </>
                    ) : (
                        <Typography color="error">
                            No changes to display. Please ensure you have selected rows to modify.
                        </Typography>
                    )}
                </Box>
                {isLoading && <CircularProgress />}
                {error && <Typography color="error">{error}</Typography>}
            </DialogContent>
            <DialogActions sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box>
                    {ignoreMsUpdates && (
                        <Typography variant="body2" fontWeight="bold">
                            Ignore Multiple Strikes: ON
                        </Typography>
                    )}
                    {useTransaction && (
                        <Typography variant="body2" fontWeight="bold">
                            Use Transaction
                        </Typography>
                    )}
                </Box>
                <Box>
                    <Button onClick={onClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={onConfirm} color="primary" variant="contained" disabled={isLoading}>
                        Confirm and Submit
                    </Button>
                </Box>

                <Box mt={2} p={2}>
                    <Button
                        startIcon={debugExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        onClick={() => setDebugExpanded(!debugExpanded)}
                        fullWidth
                    >
                        {debugExpanded ? "Hide Debug Payload" : "Show Debug Payload"}
                    </Button>
                    <Collapse in={debugExpanded}>
                        <TextField
                            value={JSON.stringify(changes, null, 2)}
                            multiline
                            fullWidth
                            rows={10}
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                            sx={{ mt: 2 }}
                        />
                    </Collapse>
                </Box>

            </DialogActions>
        </Dialog>
    );
};

export default PreviewChangesModal;
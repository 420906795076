import React from 'react';
import GraphContainer from './graph_container';

const TickerPriceHistoryPlot: React.FC<{ tickers?: string[] }> = ({ tickers }) => {
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const fetchUrl = (tickers: string[], hoursLookback: string) =>
        `${baseUrl}/market-parameter-history?tickers=${encodeURIComponent(tickers.join(','))}&hoursLookback=${hoursLookback}`;

    return (
        <GraphContainer
            tickers={tickers}
            defaultLookback="24"
            fetchUrl={fetchUrl}
            title="Historical Price per Market Ticker"
            xaxisTitle="Snapshot Time"
            yaxisTitle="Price Estimate"
        />
    );
};

export default TickerPriceHistoryPlot;

import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';
import _, { last } from 'lodash';
import DataGrid, { SelectColumn, SortColumn } from 'react-data-grid';
import {
    Select,
    MenuItem,
    Box,
    FormControl,
    Paper,
    Alert,
    Typography,
    FormControlLabel,
    Checkbox,
    InputLabel,
} from '@mui/material';
import GraphIcon from '@mui/icons-material/ShowChart';

// Styles
import 'react-data-grid/lib/styles.css';
import './styles.css';

// Types
import { Column, Row } from './row_column_type/row_col';

// Components
import DropdownEditor from './editors/dropdown_editor';
import NumericEditor from './editors/numeric_editor';
import JsonCodeEditorModal from './editors/json_code_editor_modal';
import CheckboxEditor from './editors/checkbox_editor';
import { RenderNumeric, RenderSettleCol, EventTickerCell, RenderText, RenderCheckbox, RenderNumericCommas } from './render_cells';
import StackedGraphs from './graphing/stacked_graphs';
import MarketParametersHistoryTable from './market_parameters_update_history';
import TradesHistoryTable from './trades_table';
import SubmitChangesButton from './shared_grid_components/submit_changes_button';
import MarketParametersUpdatesStatuses from './market_parameters_updates_statuses';
import OrderbookRenderer from './orderbook_renderer';
import PolymarketOrderbook from './polymarket_orderbook';
import CompactOrderbook from './compact_orderbook_display';
import InputPanel from './market_grid_inputs_panel';
import AnalysisPopup from './analysis_popup';
import { useKalshiOrderbookManager } from './kalshi_orderbook_manager';

// Hooks
import usePolymarketOrderbookManager from './polymarket_websocket';

interface FullFeaturedCrudGridProps {
    initialData: Row[];
    command: string;
    clearCommand: () => void;
    electionMode?: boolean;
    shouldShowRestingOrders: boolean;
}

const defaultColumnProperties: Partial<Column> = {
    width: 100,
    sortable: true,
    draggable: true,
};



const FullFeaturedCrudGrid: React.FC<FullFeaturedCrudGridProps> = ({
    initialData,
    command,
    clearCommand,
    electionMode,
    shouldShowRestingOrders,
}) => {
    // State for original data
    const [originalRows, setOriginalRows] = useState<Row[]>([]);

    // State for edited data
    const [editedRows, setEditedRows] = useState<Row[]>([]);

    // State for selected rows
    const [selectedRows, setSelectedRows] = useState<Set<string>>(new Set());
    const [selectedEventTickers, setSelectedEventTickers] = useState<Set<string>>(new Set());

    const [ignoreMsUpdate, setIgnoreMsUpdate] = useState<boolean>(false);
    const [useTransactionForUpdate, setUseTransactionForUpdate] = useState<boolean>(false);

    const [inputs, setInputs] = useState<{ [key: string]: any }>({});

    const { polymarketOrderbooks, setSubscribedTokens, getOrderbookByToken } = usePolymarketOrderbookManager();

    const [sortColumns, setSortColumns] = useState<SortColumn[]>([]);

    const [clearinghouseTagFilter, setClearinghouseTagFilter] = useState<string | null>(null);

    const [popupOpen, setPopupOpen] = useState(false);
    const [popupTickers, setPopupTickers] = useState<string[]>([]);

    const [lastUpdateState, setLastUpdateState] = useState<any>();

    const openPopup = (tickers: string[]) => {
        setPopupTickers(tickers);
        setPopupOpen(true);
    };

    const closePopup = useCallback(() => {
        setPopupOpen(false);
        setPopupTickers([]);
    }, [setPopupOpen, setPopupTickers]);

    const handleIgnoreMsUpdateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIgnoreMsUpdate(event.target.checked);
    };

    const handleUseTransactionForUpdateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUseTransactionForUpdate(event.target.checked);
    };

    useEffect(() => {
        if (initialData) {
            setOriginalRows(initialData);
            setEditedRows(_.cloneDeep(initialData));

            const tickers = initialData.map(row => row.market_ticker);
            const tokens = initialData
                .filter(row => row.polymarket_mapping_data?.Token0)
                .map(row => row.polymarket_mapping_data.Token0);

            setSubscribedTokens(tokens);
        } else {
            setOriginalRows([]);
            setEditedRows([]);
        }
    }, [initialData, shouldShowRestingOrders]);

    const handleRowsChange = useCallback((newRows: Row[]) => {
        setEditedRows(prevRows => [...newRows]);
    }, []);

    const handleReset = useCallback(() => {
        setEditedRows(_.cloneDeep(originalRows));
        setSelectedRows(new Set());
        setSelectedEventTickers(new Set());
    }, [originalRows]);

    const handleResetInputs = () => {
        setInputs({});
    };

    const handleInputChange = (key: string, value: any) => {
        setInputs(prev => ({ ...prev, [key]: value }));
    };

    const applyChangesToSelectedRows = () => {
        const updatedRows = editedRows.map(row => {
            if (selectedRows.has(row.market_ticker)) {
                const updatedRow = { ...row };
                Object.keys(inputs).forEach(key => {
                    if (inputs[key] !== null && inputs[key] !== "") {
                        updatedRow[key] = inputs[key];
                    }
                });
                return updatedRow;
            }
            return row;
        });
        setEditedRows(updatedRows);
    };

    const handleEventTickerClick = useCallback((eventTicker: string) => {
        setEditedRows(prevEditedRows => {
            console.log('Event Ticker Clicked:', eventTicker);

            const relevantRows = prevEditedRows.filter(row => row.event_ticker === eventTicker);

            if (relevantRows.length === 0) {
                console.warn('No rows found with the clicked event ticker');
                return prevEditedRows;
            }

            setSelectedRows(prevSelectedRows => {
                const newSelectedRows = new Set(prevSelectedRows);
                const isSelected = relevantRows.every(row => newSelectedRows.has(row.market_ticker));

                relevantRows.forEach(row => {
                    if (isSelected) {
                        newSelectedRows.delete(row.market_ticker);
                    } else {
                        newSelectedRows.add(row.market_ticker);
                    }
                });

                console.log('Updated Selected Rows:', newSelectedRows);
                return newSelectedRows;
            });

            setSelectedEventTickers(prevSelectedEventTickers => {
                const newSelectedEventTickers = new Set(prevSelectedEventTickers);

                if (newSelectedEventTickers.has(eventTicker)) {
                    newSelectedEventTickers.delete(eventTicker);
                } else {
                    newSelectedEventTickers.add(eventTicker);
                }

                console.log('Updated Selected Event Tickers:', newSelectedEventTickers);
                return newSelectedEventTickers;
            });

            return prevEditedRows;
        });
    }, [setEditedRows, setSelectedEventTickers]);

    const originalRowsRef = useRef<Row[]>([]);
    useEffect(() => {
        originalRowsRef.current = originalRows;
    }, [originalRows]);

    const handleMarketTickerClick = (eventTicker: string) => {
        const relatedMarkets = originalRowsRef.current
            .filter((r: any) => r.event_ticker === eventTicker)
            .map((r: any) => r.market_ticker);

        if (relatedMarkets.length === 0) {
            console.warn('No related markets found for the event ticker:', eventTicker);
            return;
        }

        openPopup(relatedMarkets);
    };

    const handleSubmit = async (changes: any[]) => {
        const token = localStorage.getItem('authToken');
        if (!token) {
            throw new Error('You must be logged in to submit changes.');
        }

        try {
            if (ignoreMsUpdate) {
                changes = changes.map(change => ({
                    ...change,
                    ignore_ms_update: true
                }));
            }
            if (useTransactionForUpdate) {
                const transactionId = uuidv4();
                changes = changes.map(change => ({
                    ...change,
                    transaction_id: transactionId,
                }));
            }
            changes = changes.map(change => {
                if (change.optional_params) {
                    return {
                        ...change,
                        optional_params: JSON.stringify(change.optional_params)
                    };
                }
                return change;
            });
            console.log("changes for submission:", changes);
            const baseUrl = process.env.REACT_APP_API_BASE_URL;
            const response = await axios.post(
                `${baseUrl}/market-parameter-update`,
                changes,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                }
            );

            if (response.status !== 200) {
                throw new Error(`Failed to submit changes: ${response.statusText}`);
            }

            // Handle successful response here
            console.log('Changes submitted successfully');
            return response.data;

        } catch (error) {
            if (axios.isAxiosError(error)) {
                // This is an Axios error
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.error('Error data:', error.response.data);
                    console.error('Error status:', error.response.status);
                    console.error('Error headers:', error.response.headers);
                    throw new Error(`Failed to submit changes: ${error.response.data.message || error.message}`);
                } else if (error.request) {
                    // The request was made but no response was received
                    console.error('Error request:', error.request);
                    throw new Error('No response received from server');
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.error('Error message:', error.message);
                    throw new Error('Error setting up the request');
                }
            } else {
                // This is not an Axios error
                console.error('Non-Axios error:', error);
                throw error;
            }
        }
    };

    const eventTickerColumn: Column = {
        key: 'event_ticker',
        name: 'Event Ticker',
        editable: false,
        renderCell: ({ row }: { row: Row }) => (
            <div style={{ fontSize: '12px' }}>
                <EventTickerCell row={row} onRowClick={handleEventTickerClick} />
            </div>
        ),
        width: 200,
    };

    const marketTickerColumn: Column = {
        key: 'market_ticker',
        name: 'Market Ticker',
        editable: false,
        renderCell: ({ row }: { row: Row }) => (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <span>{row.market_ticker}</span>
                <GraphIcon
                    style={{ cursor: 'pointer', marginLeft: 8 }}
                    onClick={() => handleMarketTickerClick(row.event_ticker)}
                />
            </div>
        ),
        width: 200,
    };


    const renderPolymarketOrderbook = useCallback((row: Row) => {
        if (row?.polymarket_mapping_data && row.polymarket_mapping_data.Token0) {
            const token_id0 = row.polymarket_mapping_data.Token0;
            const token_id1 = row.polymarket_mapping_data.Token1;

            return (
                <PolymarketOrderbook
                    token_id0={token_id0}
                    token_id1={token_id1}
                    getOrderbookByToken={getOrderbookByToken}
                />
            );
        }
        return <CompactOrderbook bids={[]} asks={[]} showRestingOrders={false} />;
    }, [getOrderbookByToken]);


    const {
        getLatestOrderbookByTicker,
        subscribeToMarkets,
        lastUpdate,
    } = useKalshiOrderbookManager();

    const renderOrderbookCell = useCallback(
        ({ row }: { row: Row }) => {
            const orderbook = getLatestOrderbookByTicker(row.market_ticker);
            return (
                <OrderbookRenderer
                    key={row.market_ticker}
                    marketTicker={row.market_ticker}
                    showRestingOrders={true}
                    restingYesOrders={row.resting_yes_orders || {}}
                    restingNoOrders={row.resting_no_orders || {}}
                    expectedYesOrders={row.expected_yes_orders || {}}
                    expectedNoOrders={row.expected_no_orders || {}}
                    subscribeToMarkets={subscribeToMarkets}
                    orderbook={orderbook}
                />
            );
        },
        [getLatestOrderbookByTicker, subscribeToMarkets]
    );

    const baseColumns = useMemo(() => {
        let columns: { [key: string]: Partial<Column> } = {
            select: SelectColumn,
            eventTicker: eventTickerColumn,
            marketTicker: marketTickerColumn,
            trading: {
                key: 'trading',
                name: 'Trading',
                editable: true,
                renderCell: RenderText,
                renderEditCell: (props: any) => (
                    <DropdownEditor {...props} options={['yes', 'no', 'no_refill', 'ignore']} />
                ),
            },
            liquidity: {
                key: 'liquidity',
                name: 'Liquidity',
                editable: true,
                renderEditCell: NumericEditor,
                renderCell: (props: any) => <RenderNumericCommas {...props} decimals={0} />,
                range: { min: 1, max: 10000000000 },
            },
            spread: {
                key: 'spread',
                name: 'Spread',
                editable: true,
                renderEditCell: NumericEditor,
                renderCell: RenderNumeric,
                range: { min: 0.01, max: 100 },
            },
            depth: {
                key: 'depth',
                name: 'Depth',
                editable: true,
                renderEditCell: NumericEditor,
                renderCell: RenderNumeric,
                range: { min: 1, max: 100 },
            },
            spread_min: {
                key: 'spread_min',
                name: 'Min Spread',
                editable: true,
                renderEditCell: NumericEditor,
                renderCell: RenderNumeric,
                range: { min: 0.01, max: 100 },
            },
            spread_max: {
                key: 'spread_max',
                name: 'Max Spread',
                editable: true,
                renderEditCell: NumericEditor,
                renderCell: RenderNumeric,
                range: { min: 0.01, max: 100 },
            },
            liquidity_min: {
                key: 'liquidity_min',
                name: 'Min Liquidity',
                editable: true,
                renderEditCell: NumericEditor,
                renderCell: (props: any) => <RenderNumericCommas {...props} decimals={0} />,
                range: { min: 1, max: 10000000000 },
            },
            liquidity_max: {
                key: 'liquidity_max',
                name: 'Max Liquidity',
                editable: true,
                renderEditCell: NumericEditor,
                renderCell: (props: any) => <RenderNumericCommas {...props} decimals={0} />,
                range: { min: 1, max: 10000000000 },
            },
            send_trade_alerts: {
                key: 'send_trade_alerts',
                name: 'Send Trade Alerts',
                editable: true,
                renderEditCell: CheckboxEditor,
                renderCell: RenderCheckbox,
            },
            optional_params: {
                key: 'optional_params',
                name: 'Optional Params',
                editable: true,
                renderEditCell: JsonCodeEditorModal,
                renderCell: RenderText,
            },
            price_sensitivity: {
                key: 'price_sensitivity',
                name: 'Price Sensitivity',
                editable: true,
                renderEditCell: NumericEditor,
                renderCell: RenderNumeric,
                range: { min: 1, max: 10000000000 },
            },
            spread_sensitivity: {
                key: 'spread_sensitivity',
                name: 'Spread Sensitivity',
                editable: true,
                renderEditCell: NumericEditor,
                renderCell: RenderNumeric,
                range: { min: 1, max: 10000000000 },
            },
            momentum_sensitivity: {
                key: 'momentum_sensitivity',
                name: 'Momentum Sensitivity',
                editable: true,
                renderEditCell: NumericEditor,
                renderCell: RenderNumeric,
                range: { min: 1, max: 10000000000 },
            },
            liquidity_sensitivity: {
                key: 'liquidity_sensitivity',
                name: 'Liquidity Sensitivity',
                editable: true,
                renderEditCell: NumericEditor,
                renderCell: RenderNumeric,
                range: { min: 1, max: 10000000000 },
            },
            order_time_to_expiry_ms: {
                key: 'order_time_to_expiry_ms',
                name: 'Order Time to Expiry (ms)',
                editable: true,
                renderEditCell: NumericEditor,
                renderCell: RenderNumeric,
                range: { min: 0, max: 10000000000 },
            },
            settle_exp: {
                key: 'settle_exp',
                name: 'Exp Settle',
                editable: false,
                renderCell: RenderSettleCol,
            },
            settle_yes: {
                key: 'settle_yes',
                name: 'Yes Settle',
                editable: false,
                renderCell: RenderSettleCol,
            },
            settle_no: {
                key: 'settle_no',
                name: 'No Settle',
                editable: false,
                renderCell: RenderSettleCol,
            },
            volume: {
                key: 'volume',
                name: 'Volume',
                editable: false,
                renderCell: (props: any) => <RenderNumeric {...props} decimals={0} />,
            },
            orderbook: {
                key: 'orderbook',
                name: 'Orderbook',
                width: 200,
                renderCell: renderOrderbookCell,
            },
            price_estimate: {
                key: 'price_estimate',
                name: 'Price Estimate',
                editable: true,
                renderEditCell: NumericEditor,
                renderCell: RenderNumeric,
                range: { min: 0, max: 100 },
            },
            position: {
                key: 'position',
                name: 'Position',
                editable: false,
                renderCell: RenderNumeric,
            },
            position_price: {
                key: 'position_price',
                name: 'Position Price',
                editable: false,
                renderCell: RenderNumeric,
            },
            close_time: {
                key: 'close_time',
                name: 'Close Time',
                editable: false,
                renderCell: RenderText,
                width: 200,
            },
            last_update_time: {
                key: 'last_update_time',
                name: 'Last Update Time',
                editable: false,
                renderCell: RenderText,
                width: 200,
            },
            last_price_update_time: {
                key: 'last_price_update_time',
                name: 'Last Price Update Time',
                editable: false,
                renderCell: RenderText,
                width: 200,
            },
            last_explicit_price_estimate: {
                key: 'last_price_estimate',
                name: 'Last Explicit Price Estimate',
                editable: false,
                renderCell: (props: any) => <RenderNumeric {...props} decimals={2} />,
            },
            clearinghouse_tag: {
                key: 'clearinghouse_tag',
                name: 'Clearinghouse Tag',
                editable: false,
                renderCell: RenderText,
            },
            actual_spread: {
                key: 'actual_spread',
                name: 'Actual Spread',
                editable: false,
                renderCell: RenderNumeric,
            },
        };

        if (electionMode) {
            columns['polymarket_orderbook'] = {
                key: 'polymarket_orderbook',
                name: 'Polymarket Orderbook',
                width: 300,
                renderCell: ({ row }: { row: Row }) => renderPolymarketOrderbook(row),
            };
        }

        return columns;
    }, [electionMode, renderOrderbookCell, renderPolymarketOrderbook]);

    // for custom ordering.
    const editableColumnsOrder = [
        'trading',
        'liquidity',
        'spread',
        'depth',
        'spread_min',
        'spread_max',
        'liquidity_min',
        'liquidity_max',
        'send_trade_alerts',
        'optional_params',
        'price_sensitivity',
        'spread_sensitivity',
        'momentum_sensitivity',
        'liquidity_sensitivity',
        'order_time_to_expiry_ms',
    ];

    let defaultColumnsOrder = [
        'select',
        'marketTicker',
        'eventTicker',
        'volume',
        'orderbook',
        'price_estimate',
        'trading',
        'liquidity',
        'spread',
        'settle_exp',
        'settle_yes',
        'settle_no',
        'position_price',
        'position',
        'actual_spread',
        'send_trade_alerts',
        'optional_params',

        'depth',
        'spread_min',
        'spread_max',
        'liquidity_min',
        'liquidity_max',
        'price_sensitivity',
        'spread_sensitivity',
        'momentum_sensitivity',
        'liquidity_sensitivity',
        'order_time_to_expiry_ms',
        'close_time',

        'last_update_time',
        'last_price_update_time',
        'last_explicit_price_estimate',
        'clearinghouse_tag'
    ];

    if (electionMode) {
        defaultColumnsOrder = [
            'select',
            'marketTicker',
            'eventTicker',
            'volume',
            'orderbook',
            'polymarket_orderbook',
            'price_estimate',
            'trading',
            'liquidity',
            'spread',
            'settle_exp',
            'settle_yes',
            'settle_no',
            'position_price',
            'position',
            'send_trade_alerts',
            'optional_params',

            'depth',
            'spread_min',
            'spread_max',
            'liquidity_min',
            'liquidity_max',
            'price_sensitivity',
            'spread_sensitivity',
            'momentum_sensitivity',
            'liquidity_sensitivity',
            'order_time_to_expiry_ms',
            'close_time',

            'last_update_time',
            'last_price_update_time',
            'last_explicit_price_estimate',
            'clearinghouse_tag'
        ];
        baseColumns['polymarket_orderbook'] = {
            key: 'polymarket_orderbook',
            name: 'Polymarket Orderbook',
            width: 300,
            renderCell: ({ row }: { row: Row }) => renderPolymarketOrderbook(row)
        }
    }

    const createColumns = (order: string[], baseCols: { [key: string]: Partial<Column> }): Column[] =>
        order.map(key => ({
            ...defaultColumnProperties,
            ...baseCols[key],
        }));

    const editableColumns: Column[] = useMemo(
        () => createColumns(editableColumnsOrder, baseColumns),
        [editableColumnsOrder, baseColumns]
    );
    const defaultColumns: Column[] = useMemo(
        () => createColumns(defaultColumnsOrder, baseColumns),
        [defaultColumnsOrder, baseColumns]
    );

    const [columnsOrder, setColumnsOrder] = useState(() => {
        const savedColumnsOrder = Cookies.get('columnsOrder');
        if (savedColumnsOrder) {
            try {
                return JSON.parse(savedColumnsOrder);
            } catch (e) {
                console.error('Failed to parse saved column order from cookies:', e);
            }
        }
        return defaultColumns.map((_, index) => index);
    });

    const reorderedColumns = useMemo(() => {
        return columnsOrder.map((index: number) => defaultColumns[index]);
    }, [columnsOrder, defaultColumns]);

    function onColumnsReorder(sourceKey: string, targetKey: string) {
        setColumnsOrder((columnsOrder: any) => {
            // Filter out saved columns that are not in defaultColumns
            const validColumnsOrder = columnsOrder.filter(
                (index: number) => defaultColumns[index]
            );

            const sourceColumnOrderIndex = validColumnsOrder.findIndex(
                (index: number) => defaultColumns[index].key === sourceKey
            );
            const targetColumnOrderIndex = validColumnsOrder.findIndex(
                (index: number) => defaultColumns[index].key === targetKey
            );

            // Only proceed if source and target columns are valid
            if (sourceColumnOrderIndex === -1 || targetColumnOrderIndex === -1) {
                return validColumnsOrder; // Return filtered order without changes
            }

            const newColumnsOrder = [...validColumnsOrder];
            const [sourceColumnOrder] = newColumnsOrder.splice(sourceColumnOrderIndex, 1);
            newColumnsOrder.splice(targetColumnOrderIndex, 0, sourceColumnOrder);

            // Set the new columns order in a cookie with no expiry (expires set to Infinity)
            Cookies.set('columnsOrder', JSON.stringify(newColumnsOrder), { expires: Infinity });

            return newColumnsOrder;
        });
    }

    const filteredRows = useMemo(() => {
        if (!clearinghouseTagFilter) return editedRows;
        return editedRows.filter(row => row.clearinghouse_tag === clearinghouseTagFilter);
    }, [editedRows, clearinghouseTagFilter]);

    const sortedRows = useMemo(() => {
        if (sortColumns.length === 0) return filteredRows;

        return [...filteredRows].sort((a, b) => {
            for (const sort of sortColumns) {
                const comparator = (a: Row, b: Row) => {
                    if (a[sort.columnKey] == null) return 1;
                    if (b[sort.columnKey] == null) return -1;
                    if (a[sort.columnKey] === b[sort.columnKey]) return 0;
                    return a[sort.columnKey] > b[sort.columnKey] ? 1 : -1;
                };
                const compResult = comparator(a, b);
                if (compResult !== 0) {
                    return sort.direction === 'ASC' ? compResult : -compResult;
                }
            }
            return 0;
        });
    }, [filteredRows, sortColumns]);

    return (
        <Paper elevation={3} className="gridContainer" style={{ minHeight: 'calc(100vh - 64px)', padding: 0, margin: 0 }}>
            <div className="gridContainer" style={{ padding: 0, margin: 0 }}>
                <InputPanel
                    editableColumns={editableColumns}
                    inputs={inputs}
                    handleInputChange={handleInputChange}
                    handleResetInputs={handleResetInputs}
                    applyChangesToSelectedRows={applyChangesToSelectedRows}
                    handleReset={handleReset}
                />
                <Box sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2
                }}>
                    <Box sx={{ flexGrow: 1 }}>
                        <SubmitChangesButton
                            initialRows={originalRows}
                            editedRows={editedRows}
                            selectedRows={selectedRows}
                            onSubmit={handleSubmit}
                            command={command}
                            clearCommand={clearCommand}
                            propertiesToCheck={new Set([
                                "price_estimate",
                                "trading",
                                "liquidity",
                                "spread",
                                "depth",
                                "spread_min",
                                "spread_max",
                                "liquidity_min",
                                "liquidity_max",
                                "send_trade_alerts",
                                "price_sensitivity",
                                "spread_sensitivity",
                                "momentum_sensitivity",
                                "liquidity_sensitivity",
                                "order_time_to_expiry_ms",
                                "optional_params",
                            ])}
                            comparisonKey='market_ticker'
                            ignoreMsUpdates={ignoreMsUpdate}
                            useTransaction={useTransactionForUpdate}
                        />
                    </Box>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={useTransactionForUpdate}
                                onChange={handleUseTransactionForUpdateChange}
                                color="primary"
                            />
                        }
                        label="Use Transaction"
                        sx={{ flexShrink: 0 }}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={ignoreMsUpdate}
                                onChange={handleIgnoreMsUpdateChange}
                                color="primary"
                            />
                        }
                        label="Ignore MS Update"
                        sx={{ flexShrink: 0 }}
                    />


                    <FormControl
                        variant="outlined"
                        size="small"
                        sx={{
                            minWidth: 120, // Adjust the width as needed
                            '& .MuiInputLabel-root': {
                                transform: 'translate(14px, -6px) scale(0.75)', // Ensure label shrinks and moves up properly
                            },
                            '& .MuiSelect-root': {
                                padding: '8px 14px', // Adjust padding inside the select
                            },
                            '& .MuiOutlinedInput-root': {
                                padding: '0px', // Remove unnecessary padding
                                '& fieldset': {
                                    top: 0, // Adjust fieldset position
                                },
                            },
                            '& .MuiSelect-icon': {
                                right: '8px', // Adjust icon position if needed
                            },
                        }}
                    >
                        <InputLabel
                            id="clearinghouse-tag-label"
                            shrink={true} // Force the label to shrink
                            sx={{ fontSize: '0.85rem' }} // Adjust font size to match the compact form
                        >
                            Clearinghouse Tag
                        </InputLabel>
                        <Select
                            labelId="clearinghouse-tag-label"
                            value={clearinghouseTagFilter || ''}
                            onChange={(e) => setClearinghouseTagFilter(e.target.value || null)}
                            displayEmpty
                            notched
                        >
                            <MenuItem value="">
                                <em>All</em>
                            </MenuItem>
                            {_.uniqBy(editedRows, 'clearinghouse_tag').map((row) => (
                                <MenuItem key={row.clearinghouse_tag} value={row.clearinghouse_tag}>
                                    {row.clearinghouse_tag}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>

                <DataGrid
                    rowKeyGetter={(row: Row) => row.market_ticker}
                    columns={reorderedColumns}
                    rows={sortedRows}
                    onRowsChange={handleRowsChange}
                    selectedRows={selectedRows}
                    onSelectedRowsChange={setSelectedRows}
                    defaultColumnOptions={{ resizable: true }}
                    rowHeight={35}
                    className="data-grid"
                    style={{
                        minHeight: 'calc(100vh - 128px)',
                    }}
                    onCellClick={(args: any, event: any) => {
                        event.preventGridDefault();
                        args.selectCell(true);
                    }}
                    sortColumns={sortColumns}
                    onSortColumnsChange={setSortColumns}
                    onColumnsReorder={onColumnsReorder}
                />
                <AnalysisPopup open={popupOpen} onClose={closePopup} tickers={popupTickers} />
            </div>
            <MarketParametersHistoryTable tickers={Array.from(selectedRows)} defaultNumUpdates={10} />
            <MarketParametersUpdatesStatuses tickers={Array.from(selectedRows)} />
            <TradesHistoryTable eventTickers={Array.from(selectedEventTickers)} />
            <StackedGraphs tickers={Array.from(selectedRows)} />
        </Paper>
    );
};


export default FullFeaturedCrudGrid;

import { Row } from "../row_column_type/row_col";

export const findChangesWithDiff = (
    initialData: Row[],
    modifiedData: Row[],
    selectedRows: Set<string>,
    propertiesToCheck: Set<string>,
    comparisonKey: string = "market_ticker"
) => {
    const changes: any[] = [];
    selectedRows.forEach((key) => {
        const initialItem = initialData.find(
            (item) => item[comparisonKey] === key
        );
        const modifiedItem = modifiedData.find(
            (item) => item[comparisonKey] === key
        );

        if (initialItem && modifiedItem) {
            let changeDetected = false;
            const change: { [key: string]: any } = {
                [comparisonKey]: modifiedItem[comparisonKey],
            };

            propertiesToCheck.forEach((prop) => {
                if (initialItem[prop] !== modifiedItem[prop]) {
                    changeDetected = true;
                    change[prop] = modifiedItem[prop];
                }
            });

            if (changeDetected) {
                changes.push(change);
            } else {
                changes.push({ market_ticker: modifiedItem.market_ticker });
            }
        } else if (modifiedItem) {
            const change: { [key: string]: any } = {
                [comparisonKey]: modifiedItem[comparisonKey],
            };
            propertiesToCheck.forEach((prop) => {
                change[prop] = modifiedItem[prop];
            });
            changes.push(change);
        } else if (initialItem) {
            const change: { [key: string]: any } = {
                [comparisonKey]: initialItem[comparisonKey],
            };
            propertiesToCheck.forEach((prop) => {
                change[prop] = initialItem[prop];
            });
            changes.push(change);
        }
    });
    console.log("changes:", changes);
    return changes;
};

import React from 'react';
import { Box } from '@mui/material';

interface JsonDiffViewProps {
    initialValue: any;
    newValue: any;
    propertiesToCheck: Set<string>;
    comparisonKey?: string;
}

const JsonDiffView: React.FC<JsonDiffViewProps> = ({
    initialValue,
    newValue,
    propertiesToCheck,
    comparisonKey = "market_ticker"
}) => {
    const stringifyWithHighlight = (oldObj: any, newObj: any, isOld: boolean): string => {
        const changedKeys = deepCompareObjects(oldObj, newObj);

        const highlightObject = (obj: any, path: string[] = []): string | null => {
            if (typeof obj !== 'object' || obj === null) {
                const fullPath = path.join('.');
                if (changedKeys.has(fullPath) || path.some((_, i) => changedKeys.has(path.slice(0, i + 1).join('.'))) || fullPath === comparisonKey) {
                    return JSON.stringify(obj);
                }
                return null;
            }

            const isArray = Array.isArray(obj);
            const openBracket = isArray ? '[' : '{';
            const closeBracket = isArray ? ']' : '}';

            let result = '';
            const entries = Object.entries(obj);
            const filteredEntries: [string, string | null][] = entries
                .map(([key, value]) => {
                    const currentPath = [...path, key];
                    const highlightedValue = highlightObject(value, currentPath);
                    if (highlightedValue !== null || key === comparisonKey) {
                        return [key, highlightedValue !== null ? highlightedValue : JSON.stringify(value)];
                    }
                    return null;
                })
                .filter((entry): entry is [string, string] => entry !== null);

            if (filteredEntries.length === 0 && path.length > 0) {
                return null;
            }

            result += openBracket + '\n';
            filteredEntries.forEach(([key, value], index) => {
                let line = '  '.repeat(path.length + 1);
                if (!isArray) {
                    line += `"${key}": `;
                }
                line += value;
                if (index < filteredEntries.length - 1) {
                    line += ',';
                }
                line += '\n';

                const fullPath = [...path, key].join('.');
                if ((changedKeys.has(fullPath) || path.some((_, i) => changedKeys.has(path.slice(0, i + 1).join('.')))) && key !== comparisonKey) {
                    const bgColor = isOld ? '#ffebe9' : '#e6ffec';
                    result += `<span style="background-color: ${bgColor}">${line}</span>`;
                } else {
                    result += line;
                }
            });
            result += '  '.repeat(path.length) + closeBracket;

            return result;
        };

        const highlighted = highlightObject(oldObj);
        return highlighted ? highlighted : '{}'; // Return empty object if nothing changed
    };

    const initialJson = stringifyWithHighlight(initialValue, newValue, true);
    const newJson = stringifyWithHighlight(newValue, initialValue, false);

    if (initialJson === '{}' && newJson === '{}') {
        return null; // Don't render anything if there are no changes
    }

    return (
        <Box display="flex" sx={{ fontFamily: 'monospace', fontSize: '0.875rem' }}>
            <Box flex={1} mr={0.5} sx={{ backgroundColor: '#f6f8fa', padding: '4px', borderRadius: '4px' }}>
                <pre dangerouslySetInnerHTML={{ __html: initialJson }} style={{ margin: 0, whiteSpace: 'pre-wrap', wordBreak: 'break-word' }} />
            </Box>
            <Box flex={1} ml={0.5} sx={{ backgroundColor: '#f6f8fa', padding: '4px', borderRadius: '4px' }}>
                <pre dangerouslySetInnerHTML={{ __html: newJson }} style={{ margin: 0, whiteSpace: 'pre-wrap', wordBreak: 'break-word' }} />
            </Box>
        </Box>
    );
};

function deepCompareObjects(oldObj: any, newObj: any, prefix = ''): Set<string> {
    const changedKeys = new Set<string>();
    function compare(oldValue: any, newValue: any, currentKey: string) {
        if (typeof oldValue === 'object' && oldValue !== null &&
            typeof newValue === 'object' && newValue !== null) {
            Object.keys({ ...oldValue, ...newValue }).forEach(key => {
                const nestedKey = currentKey ? `${currentKey}.${key}` : key;
                compare(oldValue[key], newValue[key], nestedKey);
            });
        } else if (oldValue !== newValue) {
            changedKeys.add(currentKey);
        }
    }
    compare(oldObj, newObj, prefix);
    return changedKeys;
}

export default JsonDiffView;
import React, { useState, useEffect, useCallback } from 'react';
import 'react-data-grid/lib/styles.css';
import './styles.css';
import { CircularProgress, Box, Button, Typography, Paper, TextField } from '@mui/material';
import DataGrid, { SelectColumn } from 'react-data-grid';
import { Row, Column } from './row_column_type/row_col';
import axios from 'axios';
import _ from 'lodash';
import NumericEditor from './editors/numeric_editor';
import SubmitChangesButton from './shared_grid_components/submit_changes_button';

interface CorrelationInfo {
    ticker_from: string;
    ticker_to: string;
    correlation: number;
}

const CorrelationGrid: React.FC = () => {
    const [correlations, setCorrelations] = useState<CorrelationInfo[]>([]);
    const [loading, setLoading] = useState(false);
    const [initialRows, setInitialRows] = useState<CorrelationInfo[]>([]);
    const [editedRows, setEditedRows] = useState<CorrelationInfo[]>([]);
    const [selectedRows, setSelectedRows] = useState<Set<string>>(new Set());
    const [selectedTicker, setSelectedTicker] = useState('');
    const [filterValue, setFilterValue] = useState('');

    const fetchCorrelationData = async (ticker: string) => {
        if (!ticker) return;
        setLoading(true);
        try {
            const baseUrl = process.env.REACT_APP_API_BASE_URL;
            const response = await axios.get(`${baseUrl}/synthetic-market-correlations`, {
                params: { ticker: ticker }
            });
            const data = response.data;
            setCorrelations(data);
            setInitialRows(data);
            setEditedRows(_.cloneDeep(data));
        } catch (error) {
            console.error('Error fetching correlation data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (selectedTicker) {
            fetchCorrelationData(selectedTicker);
        }
    }, [selectedTicker]);

    const handleRowsChange = useCallback((newRows: CorrelationInfo[]) => {
        setEditedRows(newRows);
    }, []);

    const handleSubmit = async (changes: CorrelationInfo[]) => {
        const baseUrl = process.env.REACT_APP_API_BASE_URL;
        try {
            const updatePromises = changes.map(change => {
                change.ticker_from = selectedTicker;
                return axios.post(`${baseUrl}/update-synthetic-market-correlation`, change);
            });

            await Promise.all(updatePromises);
            console.log("All changes successfully submitted.");
            if (selectedTicker) {
                fetchCorrelationData(selectedTicker); // Refresh data after successful update
            }
        } catch (error) {
            console.error("Error submitting changes:", error);
        }
    };

    const filteredRows = editedRows.filter(row =>
        row.ticker_to.toLowerCase().includes(filterValue.toLowerCase())
    );

    const columns: Column[] = [
        SelectColumn,
        { key: 'ticker_from', name: 'From Ticker', width: 350 },
        { key: 'ticker_to', name: 'To Ticker', width: 350 },
        {
            key: 'correlation',
            name: 'Correlation',
            width: 150,
            editable: true,
            renderEditCell: NumericEditor,
        },
    ];

    return (
        <Paper elevation={3} className="gridContainer" style={{ minHeight: 'calc(100vh - 64px)', padding: 20, margin: 0 }}>
            <Box className="gridContainer" sx={{ height: 'calc(100vh - 64px)', width: '100%' }}>
                <Typography variant="h4">Synthetic Market Correlations</Typography>
                <Box sx={{ display: 'flex', gap: 2, marginBottom: 2 }}>
                    <TextField
                        label="Enter Ticker"
                        variant="outlined"
                        value={selectedTicker}
                        onChange={(e) => setSelectedTicker(e.target.value)}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                fetchCorrelationData(selectedTicker);
                            }
                        }}
                    />
                    <Button variant="contained" onClick={() => fetchCorrelationData(selectedTicker)}>
                        Fetch Correlations
                    </Button>
                    <TextField
                        label="Filter To Ticker"
                        variant="outlined"
                        value={filterValue}
                        onChange={(e) => setFilterValue(e.target.value)}
                    />
                </Box>
                {loading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 400 }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <DataGrid
                        columns={columns}
                        rows={filteredRows}
                        selectedRows={selectedRows}
                        onSelectedRowsChange={setSelectedRows}
                        onRowsChange={handleRowsChange}
                        rowKeyGetter={(row: CorrelationInfo) => row.ticker_to}
                        defaultColumnOptions={{ resizable: true, sortable: true }}
                        rowHeight={35}
                        className="data-grid"
                        style={{
                            minHeight: 'calc(100vh - 200px)',
                        }}
                    />
                )}
                <SubmitChangesButton
                    initialRows={initialRows}
                    editedRows={editedRows}
                    selectedRows={selectedRows}
                    onSubmit={handleSubmit}
                    command=''
                    clearCommand={() => { }}
                    propertiesToCheck={new Set(["correlation"])}
                    comparisonKey='ticker_to'
                />
            </Box>
        </Paper>
    );
};

export default CorrelationGrid;
import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import { styled } from '@mui/material/styles';
import { EditorProps } from './types';

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
    margin: 0,
    width: '100%',
    height: '100%',
    '& .MuiCheckbox-root': {
        padding: 0,
        color: 'inherit',
    },
    '& .MuiSvgIcon-root': {
        fontSize: '0.9rem',
    },
}));

const CheckboxEditor: React.FC<EditorProps> = ({ row, column, onRowChange, onClose }) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onRowChange({ ...row, [column.key]: event.target.checked });
    };

    return (
        <StyledFormControlLabel
            control={
                <Checkbox
                    checked={!!row[column.key]}
                    onChange={handleChange}
                    onBlur={() => onClose(true)}
                    color="primary"
                    size="small"
                />
            }
            label=""
        />
    );
};

export default CheckboxEditor;
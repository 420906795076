
import React, { useState, useEffect } from 'react';
import CompactOrderbook from './compact_orderbook_display';

const PolymarketOrderbook: React.FC<{
    token_id0: string,
    token_id1: string,
    getOrderbookByToken: (tokenId: string) => any,
}> = ({ token_id0, token_id1, getOrderbookByToken }) => {
    const [time, setTime] = useState(0); // Trigger re-renders

    useEffect(() => {
        const interval = setInterval(() => {
            setTime(prev => prev + 1); // Update every second
        }, 1000);

        return () => clearInterval(interval); // Cleanup on unmount
    }, []);

    const polymarketOrderbook = getOrderbookByToken(token_id0);
    if (polymarketOrderbook) {
        return <CompactOrderbook bids={polymarketOrderbook.bids} asks={polymarketOrderbook.asks} showRestingOrders={false} />;
    }

    // only look up token0 for now (yes side).
    // const polymarketOrderbook2 = getOrderbookByToken(token_id1);
    // if (polymarketOrderbook2) {
    //     return <CompactOrderbook bids={polymarketOrderbook2.bids} asks={polymarketOrderbook2.asks} />;
    // }

    return <CompactOrderbook bids={[]} asks={[]} showRestingOrders={false} />;
};

export default PolymarketOrderbook;
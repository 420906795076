import { useEffect, useState, useCallback, useRef } from 'react';
import useWebSocket from 'react-use-websocket';
import { Orderbook, sortOrderbook } from './shared_grid_components/orderbook';

interface OrderbookMessage {
    type: string;
    timestamp: string;
    data: {
        market_ticker: string;
        yes: [number, number][];
        no: [number, number][];
    };
}

interface Orderbooks {
    [ticker: string]: Orderbook;
}

interface OrderbookEntry {
    price: number;
    size: number;
}

interface UpdateOrderbookParams {
    ticker: string;
    yes: [number, number][];
    no: [number, number][];
    wsType: string;
    timestamp: string;
}

const useKalshiOrderbookManager = () => {
    const [orderbooks, setOrderbooks] = useState<Orderbooks>({});
    const orderbooksRef = useRef<Orderbooks>({}); // Reference to avoid race conditions
    const [lastUpdate, setLastUpdate] = useState<number>(0);

    const subscribedTickers = useRef<Set<string>>(new Set());
    const pendingTickers = useRef<Set<string>>(new Set());

    const wsUrl = process.env.REACT_APP_ORDERBOOK_WS_URL;
    const wsKlearUrl = process.env.REACT_APP_ORDERBOOK_WS_KLEAR_URL;

    const reconnectInterval = 2000; // Set a shorter reconnect interval

    const {
        sendJsonMessage: sendLegacyMessage,
        readyState: legacyReadyState,
    } = useWebSocket(wsUrl || '', {
        onOpen: () => {
            console.log(`Legacy WebSocket connection established.`);
            const tickers = Array.from(subscribedTickers.current);
            if (tickers.length) subscribeToMarketsWS(tickers, 'Legacy');
        },
        onMessage: (event) => handleWsMessage(event, 'Legacy'),
        shouldReconnect: (closeEvent) => true,
        reconnectInterval, // Reconnect faster
    });

    const {
        sendJsonMessage: sendKlearMessage,
        readyState: klearReadyState,
    } = useWebSocket(wsKlearUrl || '', {
        onOpen: () => {
            console.log(`Klear WebSocket connection established.`);
            const tickers = Array.from(subscribedTickers.current);
            if (tickers.length) subscribeToMarketsWS(tickers, 'Klear');
        },
        onMessage: (event) => handleWsMessage(event, 'Klear'),
        shouldReconnect: (closeEvent) => true,
        reconnectInterval, // Reconnect faster
    });

    const subscribeToMarketsWS = useCallback(
        (tickers: string[], wsType: string) => {
            const message = { type: 'tickers', tickers };
            console.log(`${wsType} subscribing to:`, message);
            if (wsType === 'Legacy') {
                sendLegacyMessage(message);
            } else {
                sendKlearMessage(message);
            }
        },
        [sendLegacyMessage, sendKlearMessage]
    );

    const subscribeToMarkets = useCallback((tickers: string[]) => {
        tickers.forEach((ticker) => {
            if (!subscribedTickers.current.has(ticker)) {
                pendingTickers.current.add(ticker);
            }
        });
    }, []);

    const updateOrderbook = useCallback(
        ({ ticker, yes, no, wsType, timestamp }: UpdateOrderbookParams) => {
            // Update orderbooksRef directly to avoid race conditions
            const updatedOrderbook = {
                bids: yes.map(([price, size]) => ({ price, size })),
                asks: no.map(([price, size]) => ({ price, size })),
                timestamp,
            };

            orderbooksRef.current = {
                ...orderbooksRef.current,
                [ticker]: sortOrderbook(updatedOrderbook),
            };

            // Log to verify state updates
            console.log(`Orderbook updated for ${ticker}:`, orderbooksRef.current[ticker]);

            // Trigger a state update only once, which re-renders components
            setLastUpdate(Date.now());
        },
        []
    );

    const handleWsMessage = useCallback(
        (event: MessageEvent, wsType: string) => {
            try {
                const message: OrderbookMessage = JSON.parse(event.data);
                console.log(message);
                if (message.type === 'orderbook_update') {
                    const { market_ticker, yes, no } = message.data;
                    updateOrderbook({
                        ticker: market_ticker,
                        yes,
                        no,
                        wsType,
                        timestamp: message.timestamp,
                    });
                }
            } catch (error) {
                console.error(`Error parsing ${wsType} message:`, error);
            }
        },
        [updateOrderbook]
    );

    // Synchronize the ref with state on update
    useEffect(() => {
        setOrderbooks({ ...orderbooksRef.current });
    }, [lastUpdate]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (pendingTickers.current.size > 0) {
                const newTickers = Array.from(pendingTickers.current);
                pendingTickers.current.clear();
                newTickers.forEach((ticker) => subscribedTickers.current.add(ticker));
                subscribeToMarketsWS(newTickers, 'Legacy');
                subscribeToMarketsWS(newTickers, 'Klear');

                // Kick off the resubscription
                sendKlearMessage({ type: 'heartbeat' });
                sendLegacyMessage({ type: 'heartbeat' });
            }
        }, 500);

        return () => clearInterval(interval);
    }, [subscribeToMarketsWS]);

    useEffect(() => {
        const heartbeatInterval = setInterval(() => {
            sendLegacyMessage({ type: 'heartbeat' });
            sendKlearMessage({ type: 'heartbeat' });
        }, 25000); // Send a heartbeat message every 25 seconds, server expects every 30s

        return () => clearInterval(heartbeatInterval);
    }, [sendLegacyMessage, sendKlearMessage]);

    const getLatestOrderbookByTicker = useCallback(
        (ticker: string) => {
            const orderbook = orderbooksRef.current[ticker] || { bids: [], asks: [], timestamp: '' };
            return orderbook;
        },
        []
    );

    return {
        subscribeToMarkets,
        getLatestOrderbookByTicker,
        lastUpdate,
        isLegacyConnected: legacyReadyState === WebSocket.OPEN,
        isKlearConnected: klearReadyState === WebSocket.OPEN,
    };
};

export { useKalshiOrderbookManager };
export type { Orderbook };

import React, { Component, ErrorInfo } from 'react';
import Button from '@mui/material/Button';

interface ErrorBoundaryProps {
    children: React.ReactNode;
}

interface ErrorBoundaryState {
    hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: Error) {
        // Update state to trigger fallback UI
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // Log the error to the console or an error reporting service
        console.error("Uncaught error:", error, errorInfo);
    }

    handleRetry = () => {
        // Reload the application to restart
        window.location.reload();
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <div style={{ textAlign: 'center', marginTop: '50px' }}>
                    <h1>Something went wrong.</h1>
                    <Button variant="contained" color="primary" onClick={this.handleRetry}>
                        Reload
                    </Button>
                </div>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
import React, { useState, useEffect, useRef } from 'react';
import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { EditorProps } from './types';

interface NumericEditorProps extends EditorProps {
    range: { min: number; max: number };
}

const StyledTextField = styled(TextField)(({ theme }) => ({
    '& .MuiInputBase-root': {
        height: '100%',
        color: 'inherit',
        fontSize: 'inherit',
        lineHeight: 'inherit',
    },
    '& .MuiInputBase-input': {
        padding: '2px',
        height: '100%',
        color: 'inherit',
        textAlign: 'left',
        fontSize: 'inherit',
        '&::placeholder': {
            textAlign: 'left',
            fontSize: 'inherit',
            opacity: 0.7,
        },
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'inherit',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: 'inherit',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.primary.main,
    },
}));

const NumericEditor: React.FC<NumericEditorProps> = ({ column, row, onRowChange, onClose, range }) => {
    const [value, setValue] = useState("")
    const [isValid, setIsValid] = useState(true);
    const lastValidValue = useRef(row[column.key]);

    const validate = (input: string): boolean => {
        if (input === "") return true;
        const num = parseFloat(input);
        if (isNaN(num)) return false;
        if (!/^-?\d*\.?\d*$/.test(input)) return false;
        if (range) {
            if (typeof range.min === 'number' && num < range.min) return false;
            if (typeof range.max === 'number' && num > range.max) return false;
        }
        return true;
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
        let inputValue = e.target.value;
        if (validate(inputValue)) {
            const newValue = inputValue === "" ? null : parseFloat(inputValue);
            onRowChange({ ...row, [column.key]: newValue });
            lastValidValue.current = newValue;
            setIsValid(true);
        } else {
            setValue(lastValidValue.current === null ? "" : lastValidValue.current.toString());
            setIsValid(false);
        }
    };

    return (
        <StyledTextField
            type="number"
            autoFocus
            value={value}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            error={!isValid}
            inputProps={{
                style: { textAlign: 'left' }
            }}
        />
    );
};

export default NumericEditor;
import React, { useState } from "react";
import {
    Card,
    CardContent,
    Grid,
    FormControl,
    InputLabel,
    OutlinedInput,
    FormControlLabel,
    Checkbox,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
} from "@mui/material";

const InputPanel = ({
    editableColumns,
    inputs,
    handleInputChange,
    handleResetInputs,
    applyChangesToSelectedRows,
    handleReset,
}) => {
    const [openOptionalParams, setOpenOptionalParams] = useState(false);

    const handleOpenOptionalParams = () => setOpenOptionalParams(true);
    const handleCloseOptionalParams = () => setOpenOptionalParams(false);

    return (
        <Card
            elevation={1}
            sx={{
                fontSize: "0.9rem",
                padding: "12px",
                borderRadius: "8px",
                backgroundColor: "#f5f5f5",
                marginBottom: "4px",
                padding: "8px 0px 8px 8px"
            }}
        >
            <Grid container spacing={1}>
                <Grid item>
                    <Button
                        onClick={handleReset}
                        variant="contained"
                        color="secondary"
                        sx={{
                            textTransform: "none",
                            marginRight: "6px", // Less space between buttons
                            padding: "4px 12px",  // Compact button size
                        }}
                    >
                        Reset Grid State
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        onClick={handleResetInputs}
                        variant="contained"
                        color="secondary"
                        sx={{
                            textTransform: "none",
                            padding: "4px 12px",  // Compact button size
                        }}
                    >
                        Reset Inputs
                    </Button>
                </Grid>
            </Grid>

            <CardContent sx={{ padding: "8px 0 1em 0" }}>
                <Grid container spacing={1}>
                    {editableColumns.map((col) => (
                        <Grid item xs={6} sm={4} md={3} lg={2} key={col.key}>
                            <FormControl
                                fullWidth
                                variant="outlined"
                                size="small"
                                sx={{
                                    "& .MuiInputLabel-root": {
                                        position: "relative",
                                        transform: "none",
                                        marginBottom: "2px",
                                    },
                                    "& .MuiOutlinedInput-root": {
                                        "& fieldset": { top: 0 },
                                    },
                                }}
                            >
                                <InputLabel
                                    htmlFor={`input-${col.key}`}
                                    shrink
                                    sx={{
                                        fontSize: "0.7rem",
                                        fontWeight: "bold",
                                    }}
                                >
                                    {col.name}
                                </InputLabel>
                                {col.renderEditCell ? (
                                    col.renderEditCell({
                                        column: col,
                                        row: { [col.key]: inputs[col.key] || "" },
                                        onRowChange: (newRow) =>
                                            handleInputChange(col.key, newRow[col.key]),
                                        onClose: () => { },
                                    })
                                ) : col.key === "send_trade_alerts" ? (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={inputs[col.key] || false}
                                                onChange={(e) =>
                                                    handleInputChange(col.key, e.target.checked)
                                                }
                                                color="primary"
                                                sx={{
                                                    '& .MuiSvgIcon-root': { fontSize: 24 },
                                                }}
                                            />
                                        }
                                        label={col.name}
                                        sx={{
                                            margin: "0",
                                        }}
                                    />
                                ) : col.key === "optional_params" ? (
                                    <>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            onClick={handleOpenOptionalParams}
                                            fullWidth
                                            sx={{
                                                borderRadius: "6px",
                                                padding: "4px 8px",
                                            }}
                                        >
                                            Edit Optional Params
                                        </Button>
                                        <Dialog
                                            open={openOptionalParams}
                                            onClose={handleCloseOptionalParams}
                                        >
                                            <DialogTitle>
                                                Edit Optional Params
                                            </DialogTitle>
                                            <DialogContent>
                                                <TextField
                                                    label="Optional Params"
                                                    value={inputs["optional_params"] || ""}
                                                    onChange={(e) =>
                                                        handleInputChange(
                                                            "optional_params",
                                                            e.target.value
                                                        )
                                                    }
                                                    variant="outlined"
                                                    fullWidth
                                                    multiline
                                                    rows={3}
                                                    placeholder="Enter JSON or other structured data"
                                                />
                                            </DialogContent>
                                            <DialogActions>
                                                <Button
                                                    onClick={handleCloseOptionalParams}
                                                >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    onClick={handleCloseOptionalParams}
                                                >
                                                    Save
                                                </Button>
                                            </DialogActions>
                                        </Dialog>
                                    </>
                                ) : (
                                    <OutlinedInput
                                        id={`input-${col.key}`}
                                        value={inputs[col.key] || ""}
                                        onChange={(e) =>
                                            handleInputChange(col.key, e.target.value)
                                        }
                                        notched={false}
                                        sx={{
                                            height: "32px",
                                            borderRadius: "6px",
                                            "& .MuiOutlinedInput-input": {
                                                padding: "6px 10px",
                                            },
                                        }}
                                    />
                                )}
                            </FormControl>
                        </Grid>
                    ))}
                </Grid>

                <Button
                    onClick={applyChangesToSelectedRows}
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{
                        marginTop: "8px",
                        backgroundColor: "#1976d2",
                        "&:hover": { backgroundColor: "#1565c0" },
                        textTransform: "none",
                        marginBottom: "0",
                        paddingBottom: "0",
                    }}
                >
                    Apply to Selected Rows
                </Button>
            </CardContent>
        </Card>
    );
};

export default InputPanel;

import { useEffect, useState, useCallback, useRef } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { Orderbook, sortPolymarketOrderbook, Order } from './shared_grid_components/orderbook';

interface TokenOrderbook {
    [tokenId: string]: Orderbook;
}

interface PriceUpdate {
    asset_id: string;
    event_type: string;
    hash: string;
    market: string;
    price: string;
    side: "BUY" | "SELL";
    size: string;
    timestamp: string;
}

interface BookUpdate {
    asset_id: string;
    event_type: string;
    hash: string;
    market: string;
    bids: Order[];
    asks: Order[];
}

const WS_URL = 'wss://ws-subscriptions-clob.polymarket.com/ws/market';

const usePolymarketOrderbookManager = () => {
    const [orderbooks, setOrderbooks] = useState<TokenOrderbook>({});
    const [wsUrl, setWsUrl] = useState<string | null>(null);
    const [activeTokens, setActiveTokens] = useState<string[]>([]);
    const messageLog = useRef<any[]>([]);
    const polymarketOrderbooks = useRef<TokenOrderbook>({});

    const handleWsOpen = () => {
        console.log('Polymarket WebSocket connection established.');
        // Send subscription message once WebSocket is open
        if (activeTokens.length > 0) {
            subscribeToTokensWS(activeTokens);
        }
    };

    const handleWsClose = () => {
        console.log('WebSocket connection closed.');
    };

    const handleWsMessage = (event: MessageEvent) => {
        try {
            const message = JSON.parse(event.data);
            messageLog.current.push({
                timestamp: new Date().toISOString(),
                message: message,
            });
            processMessage(message);
        } catch (error) {
            console.error('Error parsing message:', error, event);
        }
    };

    const { sendJsonMessage, readyState, getWebSocket } = useWebSocket(wsUrl, {
        onOpen: handleWsOpen,
        onMessage: handleWsMessage,
        onClose: handleWsClose,
        shouldReconnect: () => true,
        reconnectAttempts: 0, // Disable automatic reconnection
        reconnectInterval: 0,
    }, !!wsUrl); // Enable WebSocket only when wsUrl is not null

    const updateOrderbook = useCallback((tokenId: string, bids: Order[], asks: Order[]) => {
        polymarketOrderbooks.current = {
            ...polymarketOrderbooks.current,
            [tokenId]: sortPolymarketOrderbook({
                bids: bids.sort((a, b) => a.price - b.price),
                asks: asks.sort((a, b) => b.price - a.price),
                timestamp: "",
            }),
        };

        setOrderbooks({ ...polymarketOrderbooks.current });
    }, []);

    const updateOrderbookSide = useCallback((tokenId: string, price: string, size: string, side: "BUY" | "SELL") => {
        setOrderbooks(prev => {
            const currentOrderbook = prev[tokenId] || { bids: [], asks: [] };
            const updatedPrice = parseFloat(price);
            const updatedSize = parseFloat(size);
            const orderSide = side === "BUY" ? "bids" : "asks";

            const filteredOrders = currentOrderbook[orderSide].filter(order => order.price !== updatedPrice);

            const updatedOrders = [...filteredOrders, { price: updatedPrice, size: updatedSize }];

            const sortedOrders = updatedOrders.sort((a, b) =>
                orderSide === "bids" ? b.price - a.price : a.price - b.price
            );

            return {
                ...prev,
                [tokenId]: {
                    ...currentOrderbook,
                    [orderSide]: sortedOrders,
                },
            };
        });
    }, []);

    const processMessage = useCallback((message: PriceUpdate | BookUpdate) => {
        if (message.event_type === 'book') {
            const { asset_id, bids, asks } = message as BookUpdate;
            updateOrderbook(asset_id, bids, asks);
        } else if (message.event_type === 'price_change') {
            const { asset_id, price, size, side } = message as PriceUpdate;
            updateOrderbookSide(asset_id, price, size, side);
        }
    }, [updateOrderbook, updateOrderbookSide]);

    const subscribeToTokensWS = useCallback((tokens: string[]) => {
        if (tokens.length < 0) {
            return;
        }
        const subscriptionMessage = {
            assets_ids: tokens,
            type: "market",
        };
        console.log("Sending subscription:", subscriptionMessage);
        sendJsonMessage(subscriptionMessage);
        console.log('Subscribed to tokens:', tokens);
    }, [sendJsonMessage]);

    const handleSetSubscribedTokens = useCallback((tokens: string[]) => {
        if (tokens.length > 0) {
            setActiveTokens(tokens);

            setWsUrl(null);
            setTimeout(() => {
                // Reopen the WebSocket after a short delay to ensure it's closed properly
                setWsUrl(WS_URL);
            }, 100); // Small delay to ensure WebSocket is closed
        } else {
            // If no tokens, close the WebSocket
            setWsUrl(null);
        }
    }, []);

    const getOrderbookByToken = useCallback((tokenId: string) => {
        return polymarketOrderbooks.current[tokenId] || { bids: [], asks: [] };
    }, []);

    useEffect(() => {
        // Send heartbeat every 30 seconds to keep the connection alive
        const heartbeatInterval = setInterval(() => {
            if (readyState === ReadyState.OPEN) {
                sendJsonMessage({ type: 'heartbeat' });
                console.log('Heartbeat sent');
            }
        }, 30000);

        return () => clearInterval(heartbeatInterval);
    }, [readyState, sendJsonMessage]);

    return {
        polymarketOrderbooks,
        setSubscribedTokens: handleSetSubscribedTokens,
        getOrderbookByToken,
        getMessageLog: () => messageLog.current,
    };
};

export default usePolymarketOrderbookManager;

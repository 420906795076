export interface Order {
    price: number;
    size: number;
}

export interface Orderbook {
    bids: Order[];
    asks: Order[];
    timestamp: string;
}

export function sortOrderbook(orderbook: Orderbook): Orderbook {
    return {
        bids: sortOrders(orderbook.bids, false),
        asks: sortOrders(orderbook.asks, false),
        timestamp: orderbook.timestamp,
    };
}

export function sortPolymarketOrderbook(orderbook: Orderbook): Orderbook {
    return {
        bids: sortOrders(orderbook.bids, false),
        asks: sortOrders(orderbook.asks, true),
        timestamp: orderbook.timestamp,
    };
}

function sortOrders(orders: Order[], descending: boolean): Order[] {
    return orders.sort((a, b) => {
        const comparison = a.price - b.price;
        return descending ? -comparison : comparison;
    });
}
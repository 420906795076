import { useEffect, useState } from "react";
import { Row } from "../row_column_type/row_col";
import { Button } from "@mui/material";
import { findChangesWithDiff } from "./shared";
import PreviewChangesModal from "./preview_changes_modal";

interface SubmitChangesButtonProps {
    initialRows: Row[];
    editedRows: Row[];
    selectedRows: Set<string>;
    onSubmit: (changes: any[]) => void;
    command: string;
    clearCommand: () => void;
    propertiesToCheck: Set<string>;
    comparisonKey: string;
    ignoreMsUpdates?: boolean;
    useTransaction?: boolean;
}

const SubmitChangesButton: React.FC<SubmitChangesButtonProps> = ({
    initialRows,
    editedRows,
    selectedRows,
    onSubmit,
    command,
    clearCommand,
    propertiesToCheck,
    comparisonKey = "market_ticker",
    ignoreMsUpdates = false,
    useTransaction = false
}) => {
    const [open, setOpen] = useState(false);
    const [changes, setChanges] = useState<any[]>([]);

    const handleOpen = () => {
        const changesWithDiff = findChangesWithDiff(
            initialRows, editedRows, selectedRows, propertiesToCheck, comparisonKey,
        );
        setChanges(changesWithDiff);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleConfirm = () => {
        setOpen(false);
        onSubmit(changes);
    };

    useEffect(() => {
        if (command === 'preview_submit' || command === 'p_s') {
            handleOpen();
            clearCommand();
        }
    }, [command, clearCommand]);

    return (
        <>
            <Button
                variant="contained"
                color="primary"
                onClick={handleOpen}
                disabled={selectedRows.size === 0}
                style={{ marginTop: '20px' }}
                fullWidth
            >
                Preview Changes
            </Button>
            <PreviewChangesModal
                open={open}
                initialData={initialRows}
                editedData={editedRows}
                selectedRows={selectedRows}
                onClose={handleClose}
                onConfirm={handleConfirm}
                propertiesToCheck={propertiesToCheck}
                changes={changes}
                comparisonKey={comparisonKey}
                ignoreMsUpdates={ignoreMsUpdates}
                useTransaction={useTransaction}
            />
        </>
    );
};


export default SubmitChangesButton;
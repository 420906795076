import React, { useState, useEffect } from 'react';
import { Box, Typography, Modal, Switch, FormControlLabel, Skeleton } from '@mui/material';

interface Order {
    price: number;
    size: number;
}

interface CompactOrderbookProps {
    bids: Order[];
    asks: Order[];
    kalshiViewMode?: boolean;
    kalshiBuyMode?: boolean;
    invertAskPrice?: boolean;
    timestamp?: string;
    restingYesOrders?: { [key: string]: number };
    restingNoOrders?: { [key: string]: number };
    expectedYesOrders?: { [key: string]: number };
    expectedNoOrders?: { [key: string]: number };
    showRestingOrders: boolean;
}

const formatNumber = (num: number | string, kalshiViewMode: boolean): string => {
    const numValue = typeof num === 'string' ? parseFloat(num) : num;
    if (isNaN(numValue)) return 'Invalid number';

    if (numValue >= 1000000) return (numValue / 1000000).toFixed(1) + 'M';
    if (numValue >= 1000) return (numValue / 1000).toFixed(1) + 'K';
    return kalshiViewMode ? numValue.toFixed(0) : numValue.toFixed(2);
};

const formatRestingExpected = (
    price: number,
    resting: { [key: string]: number },
    expected: { [key: string]: number },
    showDenominator: boolean,
): string => {
    const restingSize = resting[price] || 0;
    const expectedSize = expected[price] || 0;

    // Return an empty string if both values are 0
    if (restingSize === 0 && expectedSize === 0) {
        return '';
    }

    const formattedResting = formatNumber(restingSize, true);
    const formattedExpected = formatNumber(expectedSize, true);

    if (isNaN(restingSize) && isNaN(expectedSize)) {
        return '';
    }

    if (expectedSize > 0) {
        return showDenominator
            ? `(${formattedResting}/${formattedExpected})`
            : `(${formattedResting})`;
    }
    return `(${formattedResting})`;
};

const CompactOrderbook: React.FC<CompactOrderbookProps> = ({
    bids, asks, kalshiViewMode = false,
    kalshiBuyMode: initialKalshiBuyMode,
    invertAskPrice = false,
    timestamp = null,
    restingYesOrders = {},
    restingNoOrders = {},
    expectedYesOrders = {},
    expectedNoOrders = {},
    showRestingOrders = false,
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [kalshiBuyMode, setKalshiBuyMode] = useState(initialKalshiBuyMode || false);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => setIsLoaded(true), 1000);
        return () => clearTimeout(timer);
    }, []);

    const handleClick = () => {
        if (isLoaded && (bids.length > 0 || asks.length > 0)) {
            setIsModalOpen(true);
        }
    };

    const handleClose = () => setIsModalOpen(false);
    const handleToggleKalshiBuyMode = () => setKalshiBuyMode(!kalshiBuyMode);

    const getOrdersToDisplay = () => {
        const multiplyPrice = (orders: Order[]): Order[] =>
            orders.map(order => ({ ...order, price: order.price * 100 }));

        if (kalshiBuyMode) {
            return kalshiViewMode
                ? { buys: asks, sells: bids }
                : { buys: multiplyPrice(asks), sells: multiplyPrice(bids) };
        }
        return kalshiViewMode
            ? { buys: bids, sells: asks }
            : { buys: multiplyPrice(bids), sells: multiplyPrice(asks) };
    };

    const CompactView = () => {
        const { buys, sells } = getOrdersToDisplay();
        const hasOrders = buys.length > 0 || sells.length > 0;

        if (!isLoaded) {
            return (
                <Box sx={{ display: 'flex', gap: '4px', maxWidth: 160, padding: '2px 4px', opacity: 0.5, pointerEvents: 'none' }}>
                    <Skeleton variant="rectangular" width={75} height={24} />
                    <Skeleton variant="rectangular" width={75} height={24} />
                </Box>
            );
        }

        return (
            <Box
                onClick={handleClick}
                sx={{
                    display: 'flex',
                    fontSize: '0.75rem',
                    gap: '4px',
                    maxWidth: 160,
                    cursor: hasOrders ? 'pointer' : 'default',
                    '&:hover': hasOrders ? { backgroundColor: 'action.hover' } : {},
                    padding: '2px 4px',
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '50%' }}>
                    {buys.length > 0 ? (
                        <>
                            <Typography variant="caption" sx={{ opacity: 0.7, marginRight: '2px' }}>
                                {showRestingOrders && (
                                    <Typography variant="caption" sx={{ opacity: 0.7, marginRight: '2px' }}>
                                        {formatRestingExpected(buys[buys.length - 1].price, restingYesOrders, expectedYesOrders, false)}
                                    </Typography>
                                )}
                                {formatNumber(buys[buys.length - 1].size, kalshiViewMode)}
                            </Typography>
                            <Typography variant="caption" sx={{ color: 'success.main' }}>
                                {formatNumber(buys[buys.length - 1].price, kalshiViewMode)}{' '}
                            </Typography>
                        </>
                    ) : (
                        <Typography variant="caption" sx={{ opacity: 0.7 }}>N/A</Typography>
                    )}
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', width: '50%' }}>
                    {sells.length > 0 ? (
                        <>
                            <Typography variant="caption" sx={{ color: 'error.main' }}>
                                {formatNumber(invertAskPrice ? 100 - sells[sells.length - 1].price : sells[sells.length - 1].price, kalshiViewMode)}{' '}
                            </Typography>
                            <Typography variant="caption" sx={{ opacity: 0.7, marginLeft: '2px' }}>
                                {formatNumber(sells[sells.length - 1].size, kalshiViewMode)}
                                {showRestingOrders && (
                                    <Typography variant="caption" sx={{ opacity: 0.7, marginRight: '2px' }}>
                                        {formatRestingExpected(sells[sells.length - 1].price, restingNoOrders, expectedNoOrders, false)}
                                    </Typography>
                                )}
                            </Typography>
                        </>
                    ) : (
                        <Typography variant="caption" sx={{ opacity: 0.7 }}>N/A</Typography>
                    )}
                </Box>
            </Box>
        );
    };

    let secondsSinceLastUpdate = 0;
    if (timestamp) {
        secondsSinceLastUpdate = Math.floor((Date.now() - new Date(timestamp).getTime()) / 1000);
    }

    const ExpandedView = () => {
        const { buys, sells } = getOrdersToDisplay();

        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', fontSize: '0.75rem', gap: '8px', p: 2 }}>
                <FormControlLabel
                    control={<Switch checked={kalshiBuyMode} onChange={handleToggleKalshiBuyMode} />}
                    label="Kalshi Taker View"
                />
                <Box sx={{ display: 'flex', gap: '8px' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', width: '50%' }}>
                        <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mb: 1 }}>
                            {kalshiViewMode ? "Yes" : (kalshiBuyMode ? "Asks" : "Bids")}
                        </Typography>
                        {buys.slice().reverse().map((order, index) => (
                            <Box key={index} sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                                <Typography variant="body2" sx={{ opacity: 0.7, mr: 1 }}>
                                    {showRestingOrders && (
                                        <Typography variant="caption" sx={{ opacity: 0.7, marginRight: '2px' }}>
                                            {formatRestingExpected(order.price, restingYesOrders, expectedYesOrders, true)}
                                        </Typography>
                                    )}
                                    <span></span>
                                    {formatNumber(order.size, kalshiViewMode)}
                                </Typography>
                                <Typography variant="body2" sx={{ color: 'success.main' }}>
                                    {formatNumber(order.price, kalshiViewMode)}{' '}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '50%' }}>
                        <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mb: 1 }}>
                            {kalshiViewMode ? "No" : (kalshiBuyMode ? "Bids" : "Asks")}
                        </Typography>
                        {sells.slice().reverse().map((order, index) => (
                            <Box key={index} sx={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}>
                                <Typography variant="body2" sx={{ color: 'error.main', mr: 1 }}>
                                    {formatNumber(invertAskPrice ? 100 - order.price : sells[sells.length - 1].price, kalshiViewMode)}{' '}
                                </Typography>
                                <Typography variant="body2" sx={{ opacity: 0.7 }}>
                                    {formatNumber(order.size, kalshiViewMode)}
                                    <span></span>
                                    {showRestingOrders && (
                                        <Typography variant="caption" sx={{ opacity: 0.7, marginRight: '2px' }}>
                                            {formatRestingExpected(order.price, restingNoOrders, expectedNoOrders, true)}
                                        </Typography>
                                    )}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                </Box>
                <Box>
                    {timestamp ? (
                        <Box>
                            <div>Last Update: {new Date(timestamp).toLocaleString()}</div>
                            <div>Time Since Last Update: {secondsSinceLastUpdate} seconds ago</div>
                        </Box>
                    ) : null}
                </Box>
            </Box>
        );
    };


    return (
        <>
            <CompactView />
            <Modal
                open={isModalOpen}
                onClose={handleClose}
                aria-labelledby="expanded-orderbook-modal"
                aria-describedby="expanded-view-of-the-orderbook"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        borderRadius: 2,
                        maxWidth: 400,
                        maxHeight: '80vh',
                        overflow: 'auto',
                    }}
                >
                    <ExpandedView />
                </Box>
            </Modal>
        </>
    );
};

export default CompactOrderbook;

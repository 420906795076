import React, { useState, useEffect } from "react";
import {
    Button,
    Modal,
    Box,
    Typography,
    TextareaAutosize,
    Alert
} from "@mui/material";

interface Column {
    key: string;
}

interface Row {
    [key: string]: any;
}

interface JsonCodeEditorModalProps {
    column: Column;
    row: Row;
    onRowChange: (row: Row) => void;
}

const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

function JsonCodeEditorModal({ column, row, onRowChange }: JsonCodeEditorModalProps) {
    const [currentValue, setCurrentValue] = useState(row[column.key] ? JSON.stringify(row[column.key], null, 2) : '{}');
    const [originalValue, setOriginalValue] = useState(row[column.key] ? JSON.stringify(row[column.key], null, 2) : '{}');
    const [isValid, setIsValid] = useState(true);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [saveAttempted, setSaveAttempted] = useState(false);
    const [saveSuccess, setSaveSuccess] = useState(false);

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = e.target.value;
        const stringifiedValue = typeof value === 'object' ? JSON.stringify(value, null, 2) : value || '';
        setCurrentValue(stringifiedValue);
    };

    const handleSave = () => {
        setSaveAttempted(true);
        try {
            const parsedValue = JSON.parse(currentValue);
            setIsValid(true);
            onRowChange({ ...row, [column.key]: parsedValue });
            setSaveSuccess(true);
        } catch {
            setIsValid(false);
            setSaveSuccess(false);
        }
    };

    const handleReset = () => {
        setCurrentValue(originalValue);
        setIsValid(true);
        setSaveAttempted(false);
        setSaveSuccess(false);
    };

    const openModal = () => {
        setIsOpen(true);
        setSaveAttempted(false);
        setIsValid(true);
        setSaveSuccess(false);
    };

    const closeModal = () => {
        setIsOpen(false);
        setSaveAttempted(false);
        setSaveSuccess(false);
        // Reset to original value when closing without saving
        setCurrentValue(originalValue);
    };

    return (
        <div>
            <Button
                onClick={openModal}
                variant="contained"
                size="small"
                sx={{ fontSize: '10px', padding: '4px 8px' }}
            >
                Open Editor
            </Button>
            <Modal
                open={modalIsOpen}
                onClose={closeModal}
                aria-labelledby="json-editor-modal-title"
                aria-describedby="json-editor-modal-description"
            >
                <Box sx={modalStyle}>
                    <TextareaAutosize
                        value={currentValue}
                        onChange={handleChange}
                        minRows={20}
                        style={{ width: '100%', fontFamily: 'monospace', fontSize: 12 }}
                    />
                    {(!isValid && saveAttempted) && <Typography color="error">Invalid JSON</Typography>}
                    {saveSuccess && <Alert severity="success">Save was successful!</Alert>}
                    <Box mt={2}>
                        <Button onClick={handleSave} variant="contained" color="primary" sx={{ marginRight: '10px' }}>
                            Save
                        </Button>
                        <Button onClick={handleReset} variant="contained" color="secondary" sx={{ marginRight: '10px' }}>
                            Reset
                        </Button>
                        <Button onClick={closeModal} variant="contained" color="secondary">
                            Close
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}

export default JsonCodeEditorModal;
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Orderbook } from './kalshi_orderbook_manager';
import CompactOrderbook from './compact_orderbook_display';

interface OrderbookRendererProps {
    marketTicker: string;
    showRestingOrders: boolean;
    restingYesOrders?: Record<string, any>;
    restingNoOrders?: Record<string, any>;
    expectedYesOrders?: Record<string, any>;
    expectedNoOrders?: Record<string, any>;
    subscribeToMarkets: (tickers: string[]) => void;
    orderbook: any;
}

const OrderbookRenderer: React.FC<OrderbookRendererProps> = React.memo(({
    marketTicker,
    showRestingOrders,
    restingYesOrders = {},
    restingNoOrders = {},
    expectedYesOrders = {},
    expectedNoOrders = {},
    subscribeToMarkets,
    orderbook = {},
}) => {
    const subscribedRef = useRef(false);

    // Only subscribe once per ticker
    useEffect(() => {
        if (!subscribedRef.current) {
            subscribedRef.current = true;
            subscribeToMarkets([marketTicker]);
        }
    }, [marketTicker, subscribeToMarkets]);

    return (
        <CompactOrderbook
            bids={orderbook.bids}
            asks={orderbook.asks}
            kalshiViewMode
            kalshiBuyMode={false}
            invertAskPrice
            timestamp={orderbook.timestamp || ''}
            restingYesOrders={restingYesOrders}
            restingNoOrders={restingNoOrders}
            expectedYesOrders={expectedYesOrders}
            expectedNoOrders={expectedNoOrders}
            showRestingOrders={showRestingOrders}
        />
    );
});

const MemoizedOrderbookRenderer = React.memo(OrderbookRenderer);

export default MemoizedOrderbookRenderer;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
    Button,
    CircularProgress,
    Alert,
    Checkbox,
    FormControlLabel,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    Typography,
    IconButton
} from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';

const TradesHistoryTable = ({ eventTickers, defaultNumTrades = 100 }) => {
    const [numTrades, setNumTrades] = useState(defaultNumTrades);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [tradesData, setTradesData] = useState([]);
    const [selectedEventTicker, setSelectedEventTicker] = useState('none');
    const [showComponent, setShowComponent] = useState(true);
    const [secondsFilter, setSecondsFilter] = useState('86400');

    const defaultFields = ['ticker', 'taker_side', 'count', 'yes_price', 'no_price', 'display_time', 'is_mm_trade', 'is_taker'];

    const fetchData = async () => {
        if (selectedEventTicker === 'none') {
            setTradesData([]);
            return;
        }

        setLoading(true);
        setError(null);

        try {
            const token = localStorage.getItem('authToken');
            if (!token) {
                throw new Error('You must be logged in to fetch trades.');
            }

            const baseUrl = process.env.REACT_APP_API_BASE_URL;
            let url = `${baseUrl}/trades/${selectedEventTicker}?seconds=${secondsFilter}`;

            const response = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.status !== 200) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            setTradesData(response.data || []);
        } catch (e) {
            setError(e.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (selectedEventTicker !== 'none') {
            fetchData();
        }
    }, [selectedEventTicker, numTrades, secondsFilter]);

    const handleSubmit = (event) => {
        event.preventDefault();
        if (selectedEventTicker !== 'none') {
            fetchData();
        }
    };

    const renderTableHeader = () => {
        const fields = defaultFields;
        return (
            <TableHead>
                <TableRow>
                    {fields.map((field) => (
                        <TableCell key={field} style={{ padding: '4px', fontSize: '0.7rem', fontWeight: 'bold' }}>
                            {field.replace(/_/g, ' ').toUpperCase()}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    };

    const renderTableBody = () => {
        const fields = defaultFields;
        return (
            <TableBody>
                {tradesData.map((trade, index) => (
                    <TableRow key={index} hover className={trade.is_taker ? 'mm-taker-row' : trade.is_mm_trade ? 'mm-trade-row' : ''}>
                        {fields.map((field) => (
                            <TableCell key={field} style={{ padding: '4px', fontSize: '0.7rem' }}>
                                {trade[field] === null || trade[field] === undefined
                                    ? 'N/A'
                                    : field === 'display_time' || field === 'created_time' || field === 'updated_time'
                                        ? new Date(trade[field]).toLocaleString()
                                        : field === 'is_mm_trade' || field === 'is_taker'
                                            ? trade[field] ? 'Yes' : 'No'
                                            : trade[field].toString()}
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            </TableBody>
        );
    };

    const toggleComponentVisibility = () => {
        setShowComponent(!showComponent);
    };

    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                <Typography variant="h6" style={{ flexGrow: 1 }}>Trades History</Typography>
                <IconButton onClick={toggleComponentVisibility} size="small">
                    {showComponent ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
            </div>

            {showComponent && (
                <>
                    <form onSubmit={handleSubmit} style={{ marginBottom: '10px' }}>
                        <FormControl style={{ minWidth: 120, marginRight: '10px' }}>
                            <InputLabel>Event Ticker</InputLabel>
                            <Select
                                value={selectedEventTicker}
                                onChange={(e) => setSelectedEventTicker(e.target.value)}
                                size="small"
                            >
                                <MenuItem value="none">None</MenuItem>
                                {eventTickers.map((ticker) => (
                                    <MenuItem key={ticker} value={ticker}>{ticker}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl style={{ minWidth: 120, marginRight: '10px' }}>
                            <InputLabel>Time Range</InputLabel>
                            <Select
                                value={secondsFilter}
                                onChange={(e) => setSecondsFilter(e.target.value)}
                                size="small"
                            >
                                <MenuItem value="3600">Last hour</MenuItem>
                                <MenuItem value="86400">Last 24 hours</MenuItem>
                                <MenuItem value="604800">Last week</MenuItem>
                            </Select>
                        </FormControl>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            size="small"
                            style={{ marginRight: '10px' }}
                            disabled={selectedEventTicker === 'none'}
                        >
                            Fetch
                        </Button>
                    </form>

                    {loading && <CircularProgress size={24} />}

                    {error && <Alert severity="error" style={{ marginBottom: '10px' }}>{error}</Alert>}

                    {!loading && !error && tradesData.length > 0 && (
                        <TableContainer component={Paper} style={{ maxHeight: '400px', overflow: 'auto' }}>
                            <Table stickyHeader size="small">
                                {renderTableHeader()}
                                {renderTableBody()}
                            </Table>
                        </TableContainer>
                    )}

                    {!loading && !error && tradesData.length === 0 && selectedEventTicker !== 'none' && (
                        <Typography variant="body2" color="textSecondary">
                            No trades available. Please select an event ticker or 'All' and click 'Fetch'.
                        </Typography>
                    )}

                    {selectedEventTicker === 'none' && (
                        <Typography variant="body2" color="textSecondary">
                            Select an event ticker or 'All' to fetch trade history.
                        </Typography>
                    )}
                </>
            )}
        </div>
    );
};

export default TradesHistoryTable;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
    Button,
    CircularProgress,
    Alert,
    Checkbox,
    FormControlLabel,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    Typography,
    IconButton
} from '@mui/material';
import { ExpandMore, ExpandLess, Delete } from '@mui/icons-material';

const MarketParametersUpdatesStatuses = ({ tickers, defaultNumUpdates = 10 }) => {
    const [numUpdates, setNumUpdates] = useState(defaultNumUpdates);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [updatesData, setUpdatesData] = useState([]);
    const [showAllFields, setShowAllFields] = useState(false);
    const [selectedTicker, setSelectedTicker] = useState('none');
    const [showComponent, setShowComponent] = useState(true);

    const defaultFields = ['processing_status', 'update_timestamp', 'market_ticker', 'trading', 'price_estimate', 'spread', 'liquidity', 'update_num'];
    const allFields = [
        'update_timestamp', 'market_ticker', 'status', 'trading', 'price_estimate', 'price_sensitivity',
        'momentum', 'momentum_sensitivity', 'liquidity', 'liquidity_sensitivity',
        'liquidity_max', 'liquidity_min', 'spread', 'spread_sensitivity', 'spread_max',
        'spread_min', 'update_num', 'order_time_to_expiry_ms', 'depth', 'ignore_ms_update',
        'processing_status', 'send_trade_alerts', 'optional_params', 'force_shut_off_market',
        'transaction_id', 'primary_event_ticker'
    ];

    const fetchData = async () => {
        if (selectedTicker === 'none') {
            setUpdatesData([]);
            return;
        }

        setLoading(true);
        setError(null);

        try {
            const token = localStorage.getItem('authToken');
            if (!token) {
                throw new Error('You must be logged in to fetch data.');
            }

            const baseUrl = process.env.REACT_APP_API_BASE_URL;
            const tickersParam = encodeURIComponent(selectedTicker === 'all' ? tickers.join(',') : selectedTicker);
            const response = await axios.get(`${baseUrl}/market-parameters-updates?tickers=${tickersParam}&numUpdates=${numUpdates}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.status !== 200) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            setUpdatesData(response.data);
        } catch (e) {
            setError(e.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (selectedTicker !== 'none') {
            fetchData();
        }
    }, [selectedTicker, numUpdates]);

    const handleSubmit = (event) => {
        event.preventDefault();
        if (selectedTicker !== 'none') {
            fetchData();
        }
    };

    const handleDelete = async (marketTicker, updateNum) => {
        try {
            const token = localStorage.getItem('authToken');
            if (!token) {
                throw new Error('You must be logged in to delete updates.');
            }

            const baseUrl = process.env.REACT_APP_API_BASE_URL;
            const response = await axios.delete(`${baseUrl}/delete-market-parameters-update`, {
                params: { market_ticker: marketTicker, update_num: updateNum },
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.status !== 200) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            // Remove the deleted update from the local state
            setUpdatesData(updatesData.filter(update => !(update.market_ticker === marketTicker && update.update_num === updateNum)));
        } catch (e) {
            setError(`Failed to delete update: ${e.message}`);
        }
    };

    const renderTableHeader = () => {
        const fields = showAllFields ? allFields : defaultFields;
        return (
            <TableHead>
                <TableRow>
                    <TableCell style={{ padding: '4px', fontSize: '0.7rem', fontWeight: 'bold' }}>ACTIONS</TableCell>

                    {fields.map((field) => (
                        <TableCell key={field} style={{ padding: '4px', fontSize: '0.7rem', fontWeight: 'bold' }}>
                            {field.replace(/_/g, ' ').toUpperCase()}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    };

    const renderTableBody = () => {
        const fields = showAllFields ? allFields : defaultFields;
        return (
            <TableBody>
                {updatesData.map((row, index) => (
                    <TableRow key={index} hover>
                        <TableCell style={{ padding: '4px' }}>
                            <IconButton
                                onClick={() => handleDelete(row.market_ticker, row.update_num)}
                                size="small"
                                color="error"
                            >
                                <Delete fontSize="small" />
                            </IconButton>
                        </TableCell>
                        {fields.map((field) => (
                            <TableCell key={field} style={{ padding: '4px', fontSize: '0.7rem' }}>
                                {field === 'update_timestamp'
                                    ? new Date(row[field]).toLocaleString()
                                    : row[field] !== null ? row[field].toString() : 'N/A'}
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            </TableBody>
        );
    };

    const toggleComponentVisibility = () => {
        setShowComponent(!showComponent);
    };

    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                <Typography variant="h6" style={{ flexGrow: 1 }}>Update Statuses</Typography>
                <IconButton onClick={toggleComponentVisibility} size="small">
                    {showComponent ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
            </div>

            {showComponent && (
                <>
                    <form onSubmit={handleSubmit} style={{ marginBottom: '10px' }}>
                        <FormControl style={{ minWidth: 120, marginRight: '10px' }}>
                            <InputLabel>Market Ticker</InputLabel>
                            <Select
                                value={selectedTicker}
                                onChange={(e) => setSelectedTicker(e.target.value)}
                                size="small"
                            >
                                <MenuItem value="none">None</MenuItem>
                                <MenuItem value="all">All</MenuItem>
                                {tickers.map((ticker) => (
                                    <MenuItem key={ticker} value={ticker}>{ticker}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField
                            label="Updates"
                            type="number"
                            value={numUpdates}
                            onChange={(e) => setNumUpdates(e.target.value)}
                            style={{ marginRight: '10px' }}
                            size="small"
                        />
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            size="small"
                            style={{ marginRight: '10px' }}
                            disabled={selectedTicker === 'none'}
                        >
                            Fetch
                        </Button>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={showAllFields}
                                    onChange={(e) => setShowAllFields(e.target.checked)}
                                    size="small"
                                />
                            }
                            label="All Fields"
                        />
                    </form>

                    {loading && <CircularProgress size={24} />}

                    {error && <Alert severity="error" style={{ marginBottom: '10px' }}>{error}</Alert>}

                    {!loading && !error && updatesData && updatesData.length > 0 && (
                        <TableContainer component={Paper} style={{ maxHeight: '400px', overflow: 'auto' }}>
                            <Table stickyHeader size="small">
                                {renderTableHeader()}
                                {renderTableBody()}
                            </Table>
                        </TableContainer>
                    )}

                    {!loading && !error && (!updatesData || updatesData.length === 0) && selectedTicker !== 'none' && (
                        <Typography variant="body2" color="textSecondary">
                            No updates.
                        </Typography>
                    )}

                    {selectedTicker === 'none' && (
                        <Typography variant="body2" color="textSecondary">
                            Select a ticker or 'All' to fetch market parameter updates.
                        </Typography>
                    )}
                </>
            )}
        </div>
    );
};

export default MarketParametersUpdatesStatuses;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './styles.css';

const TradesViewer = () => {
    const [isAutoRefresh, setIsAutoRefresh] = useState(true);
    const [trades, setTrades] = useState([]);
    const [tradesCount, setTradesCount] = useState(0);
    const [tradesSum, setTradesSum] = useState(0);
    const [tickerFilter, setTickerFilter] = useState('');
    const [secondsFilter, setSecondsFilter] = useState('86400');
    const [shareCountThreshold, setShareCountThreshold] = useState(1000);
    const [status, setStatus] = useState('Active');
    const [errorMessage, setErrorMessage] = useState('');
    const [presets, setPresets] = useState([]);
    const [selectedPreset, setSelectedPreset] = useState(null);

    const refreshInterval = 5000;
    let autoRefreshIntervalId;

    useEffect(() => {
        const savedPresets = JSON.parse(localStorage.getItem('tradeViewerPresets') || '[]');
        setPresets(savedPresets);

        // Load the saved ticker filter from localStorage
        const savedTickerFilter = localStorage.getItem('tradeViewerTickerFilter') || '';
        setTickerFilterAndSave(savedTickerFilter);
    }, []);

    useEffect(() => {
        if (isAutoRefresh) {
            fetchAndUpdateTrades();
            autoRefreshIntervalId = setInterval(fetchAndUpdateTrades, refreshInterval);
        }

        return () => {
            clearInterval(autoRefreshIntervalId);
        };
    }, [isAutoRefresh, tickerFilter, secondsFilter, shareCountThreshold]);

    useEffect(() => {
        if (selectedPreset) {
            setTickerFilterAndSave(selectedPreset);
        }
    }, [selectedPreset]);

    // Function to set ticker filter and save to localStorage
    const setTickerFilterAndSave = (value) => {
        setTickerFilter(value);
        localStorage.setItem('tradeViewerTickerFilter', value);
    };

    const fetchAndUpdateTrades = async () => {
        const token = localStorage.getItem('authToken');
        if (!token) {
            setErrorMessage('You must be logged in to fetch trades.');
            setStatus('Error');
            return;
        }

        try {
            let url = `${process.env.REACT_APP_API_BASE_URL}/trades?seconds=${secondsFilter}`;
            if (tickerFilter) {
                url = `${process.env.REACT_APP_API_BASE_URL}/trades/${tickerFilter}?seconds=${secondsFilter}`;
            }

            const response = await axios.get(url, {
                headers: { Authorization: `Bearer ${token}` }
            });
            const trades = response.data;

            if (!trades) {
                setTrades([]);
            } else {
                setTrades(trades);
            }
            if (trades) {
                const totalTradeCount = trades.reduce((sum, trade) => sum + trade.count, 0);
                setTradesCount(trades.length);
                setTradesSum(totalTradeCount);
                setStatus('Active');
                setErrorMessage('');
            }
        } catch (error) {
            console.error('Error:', error);
            setErrorMessage('Failed to fetch trades. Please try again later.');
            setStatus('Error');
        }
    };

    const timeSince = (date) => {
        const seconds = Math.floor((new Date() - new Date(date)) / 1000);
        let interval = seconds / 31536000;

        if (interval > 1) return Math.floor(interval) + ' years';
        interval = seconds / 2592000;
        if (interval > 1) return Math.floor(interval) + ' months';
        interval = seconds / 86400;
        if (interval > 1) return Math.floor(interval) + ' days';
        interval = seconds / 3600;
        if (interval > 1) return Math.floor(interval) + ' hours';
        interval = seconds / 60;
        if (interval > 1) return Math.floor(interval) + ' minutes';
        return Math.floor(seconds) + ' seconds';
    };

    const handlePresetSave = () => {
        if (tickerFilter) {
            const newPresets = [...presets, { name: tickerFilter }];
            setPresets(newPresets);
            localStorage.setItem('tradeViewerPresets', JSON.stringify(newPresets));
        }
    };

    const handlePresetSelect = (event) => {
        setSelectedPreset(event.target.value);
    };

    const handlePresetDelete = () => {
        if (selectedPreset) {
            const newPresets = presets.filter((p) => p.name !== selectedPreset);
            setPresets(newPresets);
            localStorage.setItem('tradeViewerPresets', JSON.stringify(newPresets));
            setSelectedPreset(null);
        }
    };

    const handleTickerFilterChange = (e) => {
        const newValue = e.target.value;
        setTickerFilterAndSave(newValue);
    };

    return (
        <div className="container">
            <header>
                <h1>Trades Viewer</h1>
                <div className="controls">
                    <div className="filter">
                        <input
                            type="text"
                            value={tickerFilter}
                            onChange={handleTickerFilterChange}
                            placeholder="Ticker filter"
                        />
                        <select
                            value={secondsFilter}
                            onChange={e => setSecondsFilter(e.target.value)}
                        >
                            <option value="3600">Last hour</option>
                            <option value="86400">Last 24 hours</option>
                            <option value="604800">Last week</option>
                        </select>
                        <input
                            type="number"
                            value={shareCountThreshold}
                            onChange={e => setShareCountThreshold(e.target.value)}
                            placeholder="Share count threshold"
                        />
                    </div>
                    <div className="preset-controls">
                        <select
                            value={selectedPreset || ''}
                            onChange={handlePresetSelect}
                        >
                            <option value="">Select Preset</option>
                            {presets.map((preset) => (
                                <option key={preset.name} value={preset.name}>
                                    {preset.name}
                                </option>
                            ))}
                        </select>
                        <button onClick={handlePresetSave}>Save Preset</button>
                        <button onClick={handlePresetDelete} disabled={!selectedPreset}>Delete Preset</button>
                    </div>
                    <div className="toggle-switch">
                        <input
                            type="checkbox"
                            id="toggleSwitch"
                            checked={isAutoRefresh}
                            onChange={() => {
                                setIsAutoRefresh(!isAutoRefresh);
                                setStatus(!isAutoRefresh ? 'Active' : 'Inactive');
                            }}
                        />
                        <label htmlFor="toggleSwitch"></label>
                    </div>
                    <span className="toggle-label">Auto-Refresh</span>
                    <span
                        id="statusIndicator"
                        className={isAutoRefresh ? 'status-active' : 'status-inactive'}
                    >
                        {status}
                    </span>
                </div>
            </header>
            <main>
                <div className="info-bar">
                    <span>
                        Trades shown: <span id="tradesCount">{tradesCount}</span> | Total
                        volume: <span id="tradesSum">{tradesSum.toLocaleString()}</span>
                    </span>
                </div>
                {errorMessage && <div className="error-message">{errorMessage}</div>}
                <table>
                    <thead>
                        <tr>
                            <th>Ticker</th>
                            <th>Taker Side</th>
                            <th>Count</th>
                            <th>Yes Price</th>
                            <th>No Price</th>
                            <th>Time Since</th>
                            <th>Created Time</th>
                            <th>Is MM Trade</th>
                            <th>MM Is Taker</th>
                        </tr>
                    </thead>
                    <tbody>
                        {trades.map((trade, index) => (
                            <tr
                                key={index}
                                className={
                                    trade.is_taker
                                        ? 'mm-taker-row'
                                        : trade.is_mm_trade
                                            ? 'mm-trade-row'
                                            : ''
                                }
                            >
                                <td>{trade.ticker}</td>
                                <td>{trade.taker_side}</td>
                                <td
                                    className={
                                        trade.count >= shareCountThreshold ? 'high-volume-count' : ''
                                    }
                                >
                                    {trade.count}
                                </td>
                                <td>{trade.yes_price}</td>
                                <td>{trade.no_price}</td>
                                <td>{timeSince(trade.display_time)}</td>
                                <td>{trade.display_time}</td>
                                <td>{trade.is_mm_trade ? 'Yes' : 'No'}</td>
                                <td>{trade.is_taker ? 'Yes' : 'No'}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </main>
        </div>
    );
};

export default TradesViewer;
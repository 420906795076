import React, { useState } from 'react';
import axios from 'axios';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Typography,
    CircularProgress,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material';

interface RuleInfo {
    mm_id: string;
    id: string;
    market_tickers: Record<string, any>;
    event_tickers: Record<string, any>;
    share_count_threshold: number;
    slack_alert_channel_id: string;
}

const RulesList: React.FC = () => {
    const [rules, setRules] = useState<RuleInfo[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [sortAsc, setSortAsc] = useState<boolean>(true);
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [newRule, setNewRule] = useState<RuleInfo>({
        mm_id: '',
        id: '',
        market_tickers: {},
        event_tickers: {},
        share_count_threshold: 0,
        slack_alert_channel_id: '',
    });
    const [marketTickersInput, setMarketTickersInput] = useState<string>('');
    const [eventTickersInput, setEventTickersInput] = useState<string>('');

    const baseUrl = process.env.REACT_APP_API_BASE_URL;

    const loadRules = async () => {
        setLoading(true);
        setError(null);
        const url = `${baseUrl}/get-rules`;

        try {
            const response = await axios.get(url);
            setRules(response.data);
        } catch (err) {
            setError(err instanceof Error ? err.message : 'An error occurred');
        } finally {
            setLoading(false);
        }
    };

    const handleSort = () => {
        const sortedRules = [...rules].sort((a, b) => {
            return sortAsc ? a.id.localeCompare(b.id) : b.id.localeCompare(a.id);
        });
        setRules(sortedRules);
        setSortAsc(!sortAsc);
    };

    const formatData = (data: Record<string, any>): string => {
        return JSON.stringify(data, null, 2);
    };

    const handleUpsertRule = async () => {
        const token = localStorage.getItem('authToken');
        if (!token) {
            throw new Error('You must be upsert a rule.');
        }
        const url = `${baseUrl}/upsert-rule`;

        const formattedRule = {
            ...newRule,
            market_tickers: marketTickersInput.split(',').reduce((acc, ticker) => {
                acc[ticker.trim()] = {};
                return acc;
            }, {} as Record<string, any>),
            event_tickers: eventTickersInput.split(',').reduce<Record<string, any>>((acc, ticker) => {
                acc[ticker.trim()] = {};
                return acc;
            }, {}),
        };

        try {
            await axios.post(url, formattedRule, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            loadRules();
            setDialogOpen(false);
        } catch (err) {
            setError(err instanceof Error ? err.message : 'An error occurred');
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setNewRule((prev) => ({
            ...prev,
            [name]: name === 'share_count_threshold' ? parseInt(value, 10) || 0 : value,
        }));
    };

    const handleTickersChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, setTickers: React.Dispatch<React.SetStateAction<string>>) => {
        setTickers(e.target.value);
    };

    return (
        <div>
            <Button variant="contained" color="primary" onClick={loadRules}>
                Load
            </Button>

            <Button variant="contained" color="secondary" onClick={() => setDialogOpen(true)}>
                Add/Upsert Rule
            </Button>

            {loading && <CircularProgress />}
            {error && <Typography color="error">{error}</Typography>}

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>MM ID</TableCell>
                            <TableCell onClick={handleSort} style={{ cursor: 'pointer' }}>
                                Rule ID {sortAsc ? '↑' : '↓'}
                            </TableCell>
                            <TableCell>Market Tickers</TableCell>
                            <TableCell>Event Tickers</TableCell>
                            <TableCell>Share Count Threshold</TableCell>
                            <TableCell>Slack Alert Channel ID</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rules.map((rule) => (
                            <TableRow key={rule.id}>
                                <TableCell>{rule.mm_id}</TableCell>
                                <TableCell>{rule.id}</TableCell>
                                <TableCell>
                                    <pre>{formatData(rule.market_tickers)}</pre>
                                </TableCell>
                                <TableCell>
                                    <pre>{formatData(rule.event_tickers)}</pre>
                                </TableCell>
                                <TableCell>{rule.share_count_threshold}</TableCell>
                                <TableCell>{rule.slack_alert_channel_id}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
                <DialogTitle>Add or Upsert Rule</DialogTitle>
                <DialogContent>
                    <TextField
                        margin="dense"
                        label="MM ID"
                        name="mm_id"
                        fullWidth
                        value={newRule.mm_id}
                        onChange={handleInputChange}
                    />
                    <TextField
                        margin="dense"
                        label="Rule ID"
                        name="id"
                        fullWidth
                        value={newRule.id}
                        onChange={handleInputChange}
                    />
                    <TextField
                        margin="dense"
                        label="Market Tickers (comma separated)"
                        name="market_tickers"
                        fullWidth
                        value={marketTickersInput}
                        onChange={(e) => handleTickersChange(e, setMarketTickersInput)}
                    />
                    <TextField
                        margin="dense"
                        label="Event Tickers (comma separated)"
                        name="event_tickers"
                        fullWidth
                        value={eventTickersInput}
                        onChange={(e) => handleTickersChange(e, setEventTickersInput)}
                    />
                    <TextField
                        margin="dense"
                        label="Share Count Threshold"
                        name="share_count_threshold"
                        type="number"
                        fullWidth
                        value={newRule.share_count_threshold}
                        onChange={handleInputChange}
                    />
                    <TextField
                        margin="dense"
                        label="Slack Alert Channel ID"
                        name="slack_alert_channel_id"
                        fullWidth
                        value={newRule.slack_alert_channel_id}
                        onChange={handleInputChange}
                    />
                    <Typography variant="subtitle1" style={{ marginTop: '16px' }}>
                        JSON Preview:
                    </Typography>
                    <pre>{JSON.stringify({
                        ...newRule,
                        market_tickers: marketTickersInput.split(',').reduce<Record<string, any>>((acc, ticker) => {
                            acc[ticker.trim()] = {};
                            return acc;
                        }, {}),
                        event_tickers: eventTickersInput.split(',').reduce<Record<string, any>>((acc, ticker) => {
                            acc[ticker.trim()] = {};
                            return acc;
                        }, {}),
                    }, null, 2)}</pre>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDialogOpen(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleUpsertRule} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default RulesList;

import React, { useState, useEffect, memo, ChangeEvent } from 'react';
import {
    Dialog, DialogTitle, DialogContent, IconButton, Box, Typography,
    CircularProgress, Alert, TextField, Grid, Table, TableBody,
    TableCell, TableContainer, TableHead, TableRow, Paper, Button, Select, MenuItem, FormControl, InputLabel,
    SelectChangeEvent
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import PriceAndPositionGraph from './graphing/price_and_position_graph';

interface AnalysisPopupProps {
    open: boolean;
    onClose: () => void;
    tickers: string[];
}

const AnalysisPopup: React.FC<AnalysisPopupProps> = ({ open, onClose, tickers }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [positionData, setPositionData] = useState<any[]>([]);
    const [priceData, setPriceData] = useState<any[]>([]);
    const [hoursLookback, setHoursLookback] = useState<string>('24');
    const [selectedTicker, setSelectedTicker] = useState<string>(tickers[0] || '');

    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const positionHistoryUrl = (hoursLookback: string) =>
        `${baseUrl}/position-history?tickers=${encodeURIComponent(tickers.join(','))}&hoursLookback=${hoursLookback}`;
    const priceHistoryUrl = (hoursLookback: string) =>
        `${baseUrl}/market-parameter-history?tickers=${encodeURIComponent(tickers.join(','))}&hoursLookback=${hoursLookback}`;

    const handleLookbackChange = (e: ChangeEvent<HTMLInputElement>) => {
        setHoursLookback(e.target.value);
    };

    const handleTickerChange = (e: SelectChangeEvent<string>) => {
        setSelectedTicker(e.target.value);
    };

    const fetchData = async () => {
        setLoading(true);
        try {
            const [positionResponse, priceResponse] = await Promise.all([
                axios.get(positionHistoryUrl(hoursLookback), {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                    }
                }),
                axios.get(priceHistoryUrl(hoursLookback), {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                    }
                }),
            ]);

            setPositionData(positionResponse.data);
            setPriceData(priceResponse.data);
        } catch (e: any) {
            console.error('Error fetching data:', e);
            setError(e.message || 'Failed to fetch data.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (open) fetchData();
    }, [open, tickers]);

    const calculateTimeDifference = (timestamp: string) => {
        const now = new Date();
        const time = new Date(timestamp);

        // Convert both dates to UTC to avoid timezone-related discrepancies
        const nowUtc = Date.UTC(
            now.getUTCFullYear(),
            now.getUTCMonth(),
            now.getUTCDate(),
            now.getUTCHours(),
            now.getUTCMinutes(),
            now.getUTCSeconds()
        );

        const timeUtc = Date.UTC(
            time.getUTCFullYear(),
            time.getUTCMonth(),
            time.getUTCDate(),
            time.getUTCHours(),
            time.getUTCMinutes(),
            time.getUTCSeconds()
        );

        const diffMs = nowUtc - timeUtc;
        const diffHours = Math.floor(diffMs / (1000 * 60 * 60));
        const diffMinutes = Math.round((diffMs % (1000 * 60 * 60)) / (1000 * 60));

        return `${diffHours}h ${diffMinutes}m`;
    };


    const calculatePositionDeltas = (ticker: string) => {
        if (!positionData) return [];
        const tickerData = positionData
            .filter((data: any) => data.ticker === ticker)
            .sort((a: any, b: any) => new Date(a.created_time).getTime() - new Date(b.created_time).getTime());

        if (tickerData.length === 0) return [];

        const deltas: { hour: string; timestamp: string; initial: number; current: number; delta: number }[] = [];
        const initialPosition = tickerData[0].mm_position || 0;

        for (let i = 0; i < tickerData.length; i++) {
            const timestamp = new Date(tickerData[i].created_time).toISOString();
            const hour = calculateTimeDifference(tickerData[i].created_time);

            if (i === 0 || new Date(tickerData[i - 1].created_time).getHours() !== new Date(tickerData[i].created_time).getHours() || i === tickerData.length - 1) {
                const currentPosition = tickerData[i].mm_position || 0;
                const delta = currentPosition - initialPosition;
                deltas.push({ hour, timestamp, initial: initialPosition, current: currentPosition, delta });
            }
        }

        return deltas;
    };

    return (
        <Dialog
            open={open}
            onClose={() => {
                onClose();
                setLoading(true);
                setError(null);
                setPositionData([]);
                setPriceData([]);
            }}
            maxWidth="lg"
            fullWidth
        >
            <DialogTitle>
                Market Analysis
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{ position: 'absolute', right: 8, top: 8 }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
                {loading ? (
                    <CircularProgress />
                ) : error ? (
                    <Alert severity="error">{error}</Alert>
                ) : (
                    <Box>
                        <Typography variant="h6" gutterBottom>
                            Position & Price History
                        </Typography>
                        <Box sx={{ flex: 1 }}>
                            <PriceAndPositionGraph
                                tickers={tickers}
                                positionData={positionData}
                                priceData={priceData}
                            />
                        </Box>

                        <Box mb={2} display="flex" alignItems="center">
                            <TextField
                                label="Lookback Hours"
                                type="number"
                                value={hoursLookback}
                                onChange={handleLookbackChange}
                                inputProps={{ min: 1, max: 24 }}
                            />
                            <Button onClick={fetchData} variant="contained" sx={{ ml: 2 }}>
                                Refresh
                            </Button>
                        </Box>

                        <FormControl fullWidth sx={{ mt: 2, mb: 2 }}>
                            <InputLabel>Select Ticker</InputLabel>
                            <Select value={selectedTicker}
                                onChange={handleTickerChange}>
                                {tickers.map((ticker) => (
                                    <MenuItem key={ticker} value={ticker}>
                                        {ticker}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <Box mt={4}>
                            <Typography variant="h6" gutterBottom>
                                Position Deltas for {selectedTicker}
                            </Typography>
                            <TableContainer component={Paper}>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            {/* time since isn't calculating tz properly */}
                                            {/* <TableCell>Time Since</TableCell>  */}
                                            <TableCell>Timestamp</TableCell>
                                            <TableCell>Initial Position</TableCell>
                                            <TableCell>Current Position</TableCell>
                                            <TableCell>Delta</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {calculatePositionDeltas(selectedTicker).map((delta, index) => (
                                            <TableRow key={index}>
                                                {/* <TableCell>{delta.hour}</TableCell> */}
                                                <TableCell>{delta.timestamp}</TableCell>
                                                <TableCell>{delta.initial}</TableCell>
                                                <TableCell>{delta.current}</TableCell>
                                                <TableCell>{delta.delta}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default memo(AnalysisPopup, (prevProps, nextProps) =>
    prevProps.tickers.join(',') === nextProps.tickers.join(',')
);

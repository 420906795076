import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    TextField, Button, Box, Typography, Divider, RadioGroup, Radio, FormControlLabel, MenuItem,
} from '@mui/material';
import { styled } from '@mui/system';

interface MarketData {
    market_ticker: string;
    price_estimate: number;
    strike_type?: string;
    close_time: string;
    position?: number;
    position_price?: number;
    floor_strike?: number;
    event_ticker: string;
    trading: string;
}

interface GroupedMarketData {
    event_ticker: string;
    close_time: string;
    markets: MarketData[];
}

interface MarketTableProps {
    token: string | null;
}

const ScrollContainer = styled(Box)({
    display: 'flex',
    overflowX: 'auto',
    gap: '16px',
    padding: '16px',
    backgroundColor: '#f0f0f0',
});

const Card = styled(Paper)({
    minWidth: '300px',
    maxWidth: '400px',
    padding: '8px',
    backgroundColor: '#fff',
    flex: '0 0 auto',
    overflowY: 'auto',
});

const CompactTableCell = styled(TableCell)({
    padding: '5px 5px',
    fontSize: '0.85rem',
    textAlign: 'right',
    height: '60px',
});

const PositionCell = styled(TableCell)({
    padding: '8px 10px',
    fontSize: '0.85rem',
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    maxWidth: '3000px',
    height: '60px',
});

const MarketTickerCell = styled(TableCell)({
    padding: '8px 10px',
    fontSize: '0.85rem',
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    maxWidth: '300px',
    height: '60px',
});

const TradingStatusCell = styled(TableCell)<{ value?: string }>(({ theme, value }) => ({
    padding: '5px 10px',
    fontSize: '0.85rem',
    textAlign: 'right',
    height: '60px',
    borderRadius: value === '' ? '0px' : '8px',
    backgroundColor: value === '' ? 'inherit' : value === 'yes' ? 'rgba(0, 255, 0, 0.3)' : 'rgba(255, 0, 0, 0.3)',
    color: value === '' ? 'inherit' : value === 'yes' ? 'green' : 'red',
}));

// Color the rows based on value comparison
const StyledTableRow = styled(TableRow) <{ green?: boolean }>`
    background-color: ${({ green }) => (green ? '#d4edda' : '#f8d7da')};
`;

const MarketTable: React.FC<MarketTableProps> = ({ token }) => {
    const [marketData, setMarketData] = useState<GroupedMarketData[]>([]);
    const [eventTickers, setEventTickers] = useState<string[]>(["FED-24DEC", "FED-24NOV", "FED-25JAN", "FED-25MAR", "FED-25MAY", "FED-25JUN", "FED-25JUL", "FED-25SEP", "FED-25OCT", "FED-25NOV", "FED-25DEC"]);
    const [initialValue, setInitialValue] = useState<number | null>(4.75);
    const [modifications, setModifications] = useState<Record<string, number>>({});
    const [savedPaths, setSavedPaths] = useState<Record<string, Record<string, number>>>({});
    const [selectedPath, setSelectedPath] = useState<string | null>(null);

    // Fetch saved paths from localStorage on initial load
    useEffect(() => {
        const savedPathsFromStorage = localStorage.getItem('savedPaths');
        if (savedPathsFromStorage) {
            setSavedPaths(JSON.parse(savedPathsFromStorage));
        }
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            if (eventTickers.length === 0) return;

            try {
                const baseUrl = process.env.REACT_APP_API_BASE_URL;
                const queryParams = new URLSearchParams({
                    eventTickers: eventTickers.join(','),
                }).toString();

                const response = await axios.get(`${baseUrl}/market-data?${queryParams}`, {
                    headers: { Authorization: `Bearer ${token}` },
                });

                const data: MarketData[] = response.data;

                const groupedData: GroupedMarketData[] = data.reduce((acc: GroupedMarketData[], market) => {
                    const existingGroup = acc.find(group => group.event_ticker === market.event_ticker);
                    if (existingGroup) {
                        existingGroup.markets.push(market);
                    } else {
                        acc.push({
                            event_ticker: market.event_ticker,
                            close_time: market.close_time,
                            markets: [market]
                        });
                    }
                    return acc;
                }, []);

                groupedData.sort((a, b) => new Date(a.close_time).getTime() - new Date(b.close_time).getTime());

                setMarketData(groupedData);
            } catch (error) {
                console.error('Error fetching market data', error);
            }
        };

        fetchData();
    }, [eventTickers, token]);

    const roundValue = (value: number | null | undefined) => (value !== null && value !== undefined ? value.toFixed(0) : 'N/A');

    const getUniqueFloorStrikes = (): number[] => {
        const allStrikes: number[] = [];
        marketData.forEach(group => {
            group.markets.forEach(market => {
                if (market.floor_strike !== undefined && market.floor_strike !== null) {
                    if (!allStrikes.includes(market.floor_strike)) {
                        allStrikes.push(market.floor_strike);
                    }
                }
            });
        });
        return allStrikes.sort((a, b) => a - b);
    };

    const uniqueFloorStrikes = getUniqueFloorStrikes();

    const fillMissingStrikes = (markets: MarketData[], uniqueStrikes: number[]) => {
        const filledMarkets: MarketData[] = [...markets];
        uniqueStrikes.forEach((strike) => {
            if (!markets.some(market => market.floor_strike === strike)) {
                filledMarkets.push({
                    market_ticker: '',
                    price_estimate: 0,
                    close_time: '',
                    event_ticker: '',
                    floor_strike: strike,
                    position: undefined,
                    position_price: undefined,
                    strike_type: undefined,
                    trading: '',
                });
            }
        });
        return filledMarkets.sort((a, b) => (a.floor_strike || 0) - (b.floor_strike || 0));
    };

    const handleInitialValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInitialValue(Number(e.target.value));
    };

    const handleModificationChange = (event: string, value: number) => {
        setModifications({ ...modifications, [event]: value });
    };

    const calculatePathValue = (eventIndex: number): number | null => {
        if (initialValue === null) return null;
        let currentValue = initialValue;
        for (let i = 0; i <= eventIndex; i++) {
            const event = marketData[i].event_ticker;
            const modification = modifications[event] || 0;
            currentValue += modification;
        }
        return currentValue;
    };

    const handleSavePath = () => {
        const pathName = Object.keys(modifications)
            .map((event) => {
                const value = modifications[event];
                return value > 0 ? `+${value * 100}` : `${value * 100}`;
            })
            .join('/');

        if (!pathName) {
            alert('No modifications to save!');
            return;
        }

        const updatedPaths = { ...savedPaths, [pathName]: { ...modifications } };
        setSavedPaths(updatedPaths);

        localStorage.setItem('savedPaths', JSON.stringify(updatedPaths));
    };


    const handleLoadPath = (pathName: string) => {
        if (savedPaths[pathName]) {
            setModifications({ ...savedPaths[pathName] });
            setSelectedPath(pathName);  // Mark the path as selected
        }
    };

    const handleDeletePath = (pathName: string) => {
        const updatedPaths = { ...savedPaths };
        delete updatedPaths[pathName]; // Remove the selected path
        setSavedPaths(updatedPaths);

        // Save the updated paths to localStorage
        localStorage.setItem('savedPaths', JSON.stringify(updatedPaths));

        // Clear selected path if it was deleted
        if (selectedPath === pathName) {
            setSelectedPath(null);
            setModifications({}); // Reset modifications to empty after deletion
        }
    };

    const calculateSettlement = (market: MarketData, pathValue: number | null): number => {
        const isAboveFloor = market.floor_strike !== undefined && pathValue !== null && market.floor_strike < pathValue;

        let settlementValue = 0;

        if (market.position !== undefined && market.position !== null) {
            if (market.position > 0) {
                // Positive position
                settlementValue = isAboveFloor ? market.position * 1 : market.position * 0;
            } else if (market.position < 0) {
                // Negative position
                settlementValue = isAboveFloor ? 0 : Math.abs(market.position) * 1;
            }

            // Subtract position price
            settlementValue -= (market.position_price || 0) / 100 * (market.position || 0);
        }

        return settlementValue;
    };

    const calculateTotalSettlementForEvent = (markets: MarketData[], pathValue: number | null): number => {
        return markets.reduce((total, market) => total + calculateSettlement(market, pathValue), 0);
    };

    const calculateOverallSettlement = (): number => {
        return marketData.reduce((total, group, eventIndex) => {
            const pathValue = calculatePathValue(eventIndex);
            return total + calculateTotalSettlementForEvent(group.markets, pathValue);
        }, 0);
    };

    return (
        <Box>
            {/* Market Data with Intermediate Values */}
            {eventTickers.length > 0 && initialValue !== null ? (
                <ScrollContainer>
                    {marketData.map((group, eventIndex) => {
                        const pathValue = calculatePathValue(eventIndex);
                        const totalSettlementForEvent = calculateTotalSettlementForEvent(group.markets, pathValue);

                        return (
                            <Card key={group.event_ticker}>
                                <Typography variant="h6" align="center" sx={{ mb: 1, backgroundColor: 'lightgray', padding: '8px' }}>
                                    {group.event_ticker}
                                </Typography>
                                <Typography align="center" variant="subtitle2" sx={{ mb: 2 }}>
                                    Close Time: {new Date(group.close_time).toLocaleString()}
                                </Typography>

                                <Typography variant="subtitle1" align="center" sx={{ mb: 2, fontWeight: 'bold' }}>
                                    Intermediate Value: {pathValue}
                                </Typography>

                                <Divider sx={{ mb: 2 }} />

                                <Table stickyHeader size="small" sx={{ tableLayout: 'fixed' }}>
                                    <TableHead>
                                        <TableRow style={{ fontSize: '0.3rem' }}>
                                            <MarketTickerCell align="center"><strong>Market_Ticker</strong></MarketTickerCell>
                                            <CompactTableCell align="center"><strong>Price_Estimate</strong></CompactTableCell>
                                            <PositionCell align="center"><strong>Position</strong></PositionCell>
                                            <CompactTableCell align="center"><strong>Position_Price</strong></CompactTableCell>
                                            <CompactTableCell align="center"><strong>Floor_Strike</strong></CompactTableCell>
                                            <PositionCell align="center"><strong>Settlement</strong></PositionCell>
                                            <CompactTableCell align="center"><strong>Status</strong></CompactTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {fillMissingStrikes(group.markets, uniqueFloorStrikes).map((market, index) => {
                                            const settlementValue = calculateSettlement(market, pathValue);
                                            return (
                                                <StyledTableRow
                                                    key={index}
                                                    green={market.floor_strike !== undefined && pathValue !== null && market.floor_strike < pathValue}
                                                >
                                                    <MarketTickerCell align="center">{market.market_ticker || ' '}</MarketTickerCell>
                                                    <CompactTableCell align="center">{market.market_ticker ? roundValue(market.price_estimate) : ' '}</CompactTableCell>

                                                    <PositionCell align="center">{market.position !== undefined && market.position !== null ? market.position : '0'}</PositionCell>

                                                    <CompactTableCell align="center">{market.market_ticker ? roundValue(market.position_price) : ' '}</CompactTableCell>
                                                    <CompactTableCell align="center">{market.floor_strike}</CompactTableCell>
                                                    <PositionCell align="center">{roundValue(settlementValue)}</PositionCell>
                                                    <TradingStatusCell align="center" value={market.trading}>
                                                        {market.trading || ' '}
                                                    </TradingStatusCell>
                                                </StyledTableRow>
                                            );
                                        })}
                                    </TableBody>

                                </Table>

                                {/* Display settlement for this event */}
                                <Typography variant="subtitle1" align="center" sx={{ mt: 2 }}>
                                    Settlement for {group.event_ticker}: {roundValue(totalSettlementForEvent)}
                                </Typography>
                            </Card>
                        );
                    })}
                </ScrollContainer>
            ) : (
                <p>Please add event tickers and enter an initial value to fetch market data.</p>
            )}

            {/* Display the overall settlement value */}
            <Typography variant="h5" align="center" sx={{ mt: 4, fontWeight: 'bold' }}>
                Overall Settlement Value: {roundValue(calculateOverallSettlement())}
            </Typography>

            {/* Initial Value Input */}
            <Box display="flex" alignItems="center" mb={2}>
                <TextField
                    label="Initial Value"
                    variant="outlined"
                    type="number"
                    value={initialValue || ''}
                    onChange={handleInitialValueChange}
                    sx={{ mr: 2 }}
                />
            </Box>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {marketData.map((group) => (
                                <TableCell key={group.event_ticker} align="center">
                                    <Typography variant="subtitle1">
                                        {group.event_ticker} (Close: {new Date(group.close_time).toLocaleDateString()})
                                    </Typography>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            {marketData.map((group) => (
                                <TableCell key={`${group.event_ticker}-input`} align="center">
                                    <TextField
                                        select
                                        value={modifications[group.event_ticker] !== undefined ? modifications[group.event_ticker] : ''}
                                        onChange={(e) => handleModificationChange(group.event_ticker, parseFloat(e.target.value))}
                                        variant="outlined"
                                        label="Value"
                                        sx={{ width: '100%' }}
                                        SelectProps={{
                                            native: false,
                                        }}
                                    >
                                        <MenuItem value={0}>0</MenuItem>
                                        <MenuItem value={0.25}>+25</MenuItem>
                                        <MenuItem value={0.50}>+50</MenuItem>
                                        <MenuItem value={-0.25}>-25</MenuItem>
                                        <MenuItem value={-0.50}>-50</MenuItem>
                                        <MenuItem value="">
                                            <TextField
                                                label="Custom"
                                                variant="standard"
                                                onChange={(e) => handleModificationChange(group.event_ticker, parseFloat(e.target.value))}
                                                placeholder="Enter custom value"
                                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                                sx={{ mt: 1 }}
                                            />
                                        </MenuItem>
                                    </TextField>

                                </TableCell>
                            ))}
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Save Path Input */}
            <Box display="flex" alignItems="center" mb={2}>
                <Button variant="contained" color="primary" onClick={() => handleSavePath()}>
                    Save Path
                </Button>
            </Box>

            {/* Path Selector List with Hover for Preview */}
            {Object.keys(savedPaths).length > 0 && (
                <Box mt={4}>
                    <Typography variant="h6" gutterBottom>
                        Saved Paths:
                    </Typography>
                    <Box
                        sx={{
                            border: '1px solid #ddd',
                            borderRadius: '8px',
                            padding: '16px',
                            backgroundColor: '#f9f9f9',
                        }}
                    >
                        {Object.keys(savedPaths).map((pathName) => (
                            <Box
                                key={pathName}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    padding: '8px',
                                    marginBottom: '8px',
                                    cursor: 'pointer',
                                    backgroundColor: selectedPath === pathName ? '#e0f7fa' : '#fff',
                                    border: '1px solid #ccc',
                                    borderRadius: '4px',
                                    transition: 'background-color 0.3s ease',
                                    '&:hover': {
                                        backgroundColor: '#e0f7fa',
                                    },
                                }}
                            >
                                <Typography
                                    variant="body1"
                                    onClick={() => handleLoadPath(pathName)}
                                    sx={{ flexGrow: 1 }}
                                >
                                    {pathName}
                                </Typography>
                                <Button
                                    variant="outlined"
                                    color="error"
                                    size="small"
                                    onClick={(e) => {
                                        e.stopPropagation(); // Prevents triggering the click on the entire box
                                        handleDeletePath(pathName);
                                    }}
                                    sx={{ ml: 2 }}
                                >
                                    Delete
                                </Button>
                            </Box>
                        ))}
                    </Box>
                </Box>
            )}



        </Box>
    );
};

export default MarketTable;
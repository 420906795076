import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Paper,
    CircularProgress,
    Alert,
    Typography,
    Grid,
    Button,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    TextField,
    Box
} from '@mui/material';

const MarketOrderInfoTable = () => {
    const [orderInfo, setOrderInfo] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [autoRefresh, setAutoRefresh] = useState(true);
    const [sortBy, setSortBy] = useState('market_ticker');
    const [prefixFilter, setPrefixFilter] = useState('');
    const [antiPrefixFilter, setAntiPrefixFilter] = useState('');

    const fetchOrderInfo = useCallback(async () => {
        setLoading(true);
        setError(null);

        try {
            const token = localStorage.getItem('authToken');
            if (!token) {
                throw new Error('You must be logged in to fetch order info.');
            }

            // This is split between two api endpoints, somewhat messy.
            const baseUrl = process.env.REACT_APP_API_BASE_URL_KLEAR_BACKEND;

            let filterQuery = '';
            console.log("Filters:", prefixFilter, antiPrefixFilter);
            if (prefixFilter && prefixFilter !== '') {
                filterQuery = `prefix=${encodeURIComponent(prefixFilter)}`;
            } else if (antiPrefixFilter && antiPrefixFilter != '') {
                filterQuery = `antiPrefix=${encodeURIComponent(antiPrefixFilter)}`;
            }

            const url = `${baseUrl}/get-market-and-order-info?min=true&${filterQuery}`;

            const response = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            const data = Object.values(response.data).map((order) => ({
                ...order,
                spreadDiff: Math.abs(order.actual_spread - order.expected_spread) || 0,
                topYesOrder: order.top_yes_order || {},
                topNoOrder: order.top_no_order || {},
                totalMissingOrders: calculateMissingOrders(
                    order.resting_yes_orders,
                    order.expected_yes_orders,
                    order.resting_no_orders,
                    order.expected_no_orders
                )
            }));

            setOrderInfo(data);
        } catch (e) {
            setError(e.message);
        } finally {
            setLoading(false);
        }
    }, [prefixFilter, antiPrefixFilter]);

    const calculateMissingOrders = (restingYes, expectedYes, restingNo, expectedNo) => {
        const topYesPrice = Math.max(...Object.keys(expectedYes).map(Number));
        const topNoPrice = Math.max(...Object.keys(expectedNo).map(Number));

        const missingYes = (expectedYes[topYesPrice] || 0) - (restingYes[topYesPrice] || 0);
        const missingNo = (expectedNo[topNoPrice] || 0) - (restingNo[topNoPrice] || 0);

        const totalMissing = missingYes + missingNo;
        return isNaN(totalMissing) ? 'N/A' : totalMissing;
    };

    useEffect(() => {
        fetchOrderInfo();

        let interval;
        if (autoRefresh) {
            interval = setInterval(fetchOrderInfo, 30000);
        }
        return () => clearInterval(interval);
    }, [fetchOrderInfo, autoRefresh]);

    const handleRefreshToggle = () => {
        setAutoRefresh((prev) => !prev);
    };

    const handleSortChange = (event) => {
        setSortBy(event.target.value);
    };

    const handlePrefixFilterChange = (event) => {
        setPrefixFilter(event.target.value);
    };

    const handleAntiPrefixFilterChange = (event) => {
        setAntiPrefixFilter(event.target.value);
    };

    const handleForceRefresh = (event) => {
        fetchOrderInfo();
    };

    const filterOrderInfo = (data) => {
        return data.filter((order) => {
            const matchesPrefix = prefixFilter ? order.market_ticker.startsWith(prefixFilter) : true;
            const matchesAntiPrefix = antiPrefixFilter ? !order.market_ticker.startsWith(antiPrefixFilter) : true;
            return matchesPrefix && matchesAntiPrefix;
        });
    };

    const sortOrderInfo = (data) => {
        return [...data].sort((a, b) => {
            switch (sortBy) {
                case 'spreadDiff':
                    return b.spreadDiff - a.spreadDiff;
                case 'totalMissingOrders':
                    return b.totalMissingOrders - a.totalMissingOrders;
                case 'market_ticker':
                    return a.market_ticker.localeCompare(b.market_ticker);
                default:
                    return 0;
            }
        });
    };

    const renderOrderInfoRow = (order) => (
        <>
            <Typography variant="body2" gutterBottom>
                <span style={{ fontWeight: 'bold' }}>{order.market_ticker}</span> |
                {order.topYesOrder.yes_price || 'N/A'}@{100 - order.topNoOrder.no_price || 'N/A'} |
                Spread Actual: {order.actual_spread} (Expected: {order.expected_spread}) |
                Spread Diff:
                <span style={{ fontWeight: order.spreadDiff !== 0 ? 'bold' : 'normal' }}>
                    {order.spreadDiff}
                </span> |
                Missing Orders at Top Tranchs:
                <span style={{ fontWeight: 'bold' }}>{order.totalMissingOrders}</span>
            </Typography>

            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Typography variant="body2" style={{ fontWeight: 'bold' }}>Side: yes</Typography>
                    {renderRestingOrders(order.resting_yes_orders, order.expected_yes_orders)}
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body2" style={{ fontWeight: 'bold' }}>Side: no</Typography>
                    {renderRestingOrders(order.resting_no_orders, order.expected_no_orders)}
                </Grid>
            </Grid>
        </>
    );

    const renderRestingOrders = (orders = {}, expectedOrders = {}) => {
        // Collect all unique price keys
        const orderKeys = Array.from(
            new Set([...Object.keys(orders), ...Object.keys(expectedOrders)].map(Number))
        ).sort((a, b) => b - a);

        // Determine the highest price where expected is non-zero
        const highestNonZeroExpectedPrice = Math.max(
            ...orderKeys.filter((price) => expectedOrders[price] > 0)
        );

        // Filter prices to include only those up to the highest non-zero expected price
        const filteredOrderKeys = orderKeys.filter(
            (price) => price <= highestNonZeroExpectedPrice && expectedOrders[price] > 0
        );

        return filteredOrderKeys.map((price) => {
            const resting = orders[price] || 0;
            const expected = expectedOrders[price] || 0;
            const ratio = expected > 0 ? (resting / expected).toFixed(2) : 'N/A';

            return (
                <Typography key={price} variant="body2">
                    Price: {price} | Resting: {resting}/{expected} ({ratio})
                </Typography>
            );
        });
    };


    return (
        <div>
            <Box
                display="flex"
                alignItems="center"
                gap={1} // Use consistent gap between elements
                mb={1}
                p={1}
                style={{
                    backgroundColor: '#f0f0f0',
                    borderRadius: '8px',
                    maxHeight: '50px',
                }}
            >
                <Button
                    onClick={handleForceRefresh}
                    variant="contained"
                    color={autoRefresh ? 'primary' : 'secondary'}
                    style={{
                        minWidth: '120px',
                        padding: '5px 8px',
                        fontSize: '12px',
                        whiteSpace: 'nowrap',
                    }}
                >
                    Force Refresh
                </Button>

                <Button
                    onClick={handleRefreshToggle}
                    variant="contained"
                    color={autoRefresh ? 'primary' : 'secondary'}
                    style={{
                        minWidth: '120px',
                        padding: '5px 8px',
                        fontSize: '12px',
                        whiteSpace: 'nowrap',
                    }}
                >
                    {autoRefresh ? 'Stop Auto-Refresh' : 'Start Auto-Refresh'}
                </Button>

                <FormControl
                    variant="outlined"
                    size="small"
                    style={{ minWidth: 140 }}
                >
                    <InputLabel>Sort By</InputLabel>
                    <Select
                        value={sortBy}
                        onChange={handleSortChange}
                        label="Sort By" // Prevents overlap with label
                    >
                        <MenuItem value="market_ticker">Market Ticker</MenuItem>
                        <MenuItem value="spreadDiff">Spread Diff</MenuItem>
                        <MenuItem value="totalMissingOrders">Missing Orders</MenuItem>
                    </Select>
                </FormControl>

                <TextField
                    label="Prefix Filter"
                    value={prefixFilter}
                    onChange={handlePrefixFilterChange}
                    size="small"
                    style={{
                        maxWidth: '130px',
                        marginLeft: '8px',
                    }}
                />

                <TextField
                    label="Anti-Prefix Filter"
                    value={antiPrefixFilter}
                    onChange={handleAntiPrefixFilterChange}
                    size="small"
                    style={{
                        maxWidth: '130px',
                        marginLeft: '8px',
                    }}
                />
            </Box>

            {loading && <CircularProgress size={24} />}
            {error && <Alert severity="error">{error}</Alert>}

            {!loading && !error && orderInfo.length > 0 && (
                <TableContainer component={Paper}>
                    <Table size="small">
                        <TableBody>
                            {sortOrderInfo(filterOrderInfo(orderInfo)).map((order, index) => (
                                <TableRow key={index}>
                                    <TableCell colSpan={2}>{renderOrderInfoRow(order)}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}

            {!loading && !error && orderInfo.length === 0 && (
                <Typography variant="body2" color="textSecondary">
                    No order information available.
                </Typography>
            )}
        </div>
    );
};

export default MarketOrderInfoTable;

import React, { useState, useEffect, useRef } from 'react';
import Plot from 'react-plotly.js';
import { createChart, IChartApi, ISeriesApi, LineData, Time, CrosshairMode } from 'lightweight-charts';
import axios from 'axios';
import { Button, Grid, CircularProgress, Alert, TextField, IconButton, Switch, FormControlLabel } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

interface GraphContainerProps {
    tickers?: string[];
    defaultLookback: string;
    fetchUrl: (tickers: string[], hoursLookback: string) => string;
    title: string;
    xaxisTitle: string;
    yaxisTitle: string;
}

const GraphContainer: React.FC<GraphContainerProps> = ({ tickers: propTickers, defaultLookback, fetchUrl, title, xaxisTitle, yaxisTitle }) => {
    const [tickers, setTickers] = useState(propTickers || []);
    const [hoursLookback, setHoursLookback] = useState(defaultLookback);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [plotData, setPlotData] = useState<any>({
        data: [],
        layout: {
            title,
            xaxis: { title: xaxisTitle },
            yaxis: { title: yaxisTitle },
        }
    });
    const [showGraph, setShowGraph] = useState(false);
    const [isMinimized, setIsMinimized] = useState(false);
    const [useLightweightCharts, setUseLightweightCharts] = useState(false);
    const chartRef = useRef<IChartApi | null>(null);
    const seriesRef = useRef<ISeriesApi<'Line'>[]>([]);
    const chartContainerRef = useRef<HTMLDivElement>(null);
    const legendRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (propTickers) {
            setTickers(propTickers);
        }
    }, [propTickers]);

    useEffect(() => {
        return () => {
            // Cleanup function to remove the chart when the component unmounts
            if (chartRef.current) {
                chartRef.current.remove();
                chartRef.current = null;
            }
        };
    }, []);

    useEffect(() => {
        if (showGraph && useLightweightCharts) {
            const timer = setTimeout(() => {
                createLightweightChart();
            }, 0);
            return () => clearTimeout(timer);
        }
    }, [showGraph, useLightweightCharts]);

    const createLightweightChart = () => {
        if (!chartContainerRef.current) {
            console.error('Chart container ref is null');
            return;
        }

        if (chartRef.current) {
            chartRef.current.remove();
        }

        try {
            chartRef.current = createChart(chartContainerRef.current, {
                width: chartContainerRef.current.clientWidth,
                height: 400,
                layout: {
                    background: { color: 'white' },
                    textColor: 'black',
                },
                grid: {
                    vertLines: { color: '#e0e0e0' },
                    horzLines: { color: '#e0e0e0' },
                },
                crosshair: {
                    mode: CrosshairMode.Normal,
                },
                timeScale: {
                    timeVisible: true,
                    secondsVisible: false,
                },
            });

            // Add dummy data
            const dummyData = generateDummyData();
            addSeriesWithMarkers(chartRef.current, 'Dummy Data', dummyData, '#2196f3');

            // Fit content
            chartRef.current.timeScale().fitContent();

            const handleResize = () => {
                if (chartRef.current && chartContainerRef.current) {
                    chartRef.current.applyOptions({
                        width: chartContainerRef.current.clientWidth,
                    });
                }
            };

            window.addEventListener('resize', handleResize);

            return () => {
                window.removeEventListener('resize', handleResize);
            };
        } catch (error) {
            console.error('Error creating Lightweight chart:', error);
            setError('Failed to create chart. Please try again.');
        }
    };

    const generateDummyData = (): LineData[] => {
        const dummyData: LineData[] = [];
        const now = new Date();
        for (let i = 0; i < 100; i++) {
            dummyData.push({
                time: (now.getTime() / 1000 - i * 3600) as Time,
                value: Math.random() * 100
            });
        }
        return dummyData.reverse();
    };

    const addSeriesWithMarkers = (chart: IChartApi, name: string, data: LineData[], color: string) => {
        if (!chart) {
            console.error('Chart is null in addSeriesWithMarkers');
            return;
        }

        try {
            const lineSeries = chart.addLineSeries({
                color: color,
                lineWidth: 2,
                title: name,
            });

            lineSeries.setData(data);

            const markers = data.map((point) => ({
                time: point.time,
                position: 'inBar' as const,
                color: color,
                shape: 'circle' as const,
                size: 0.5,
            }));

            lineSeries.setMarkers(markers);
            seriesRef.current.push(lineSeries);

            // Add to legend
            if (legendRef.current) {
                const legendItem = document.createElement('div');
                legendItem.style.display = 'flex';
                legendItem.style.alignItems = 'center';
                legendItem.style.marginRight = '10px';

                const colorBox = document.createElement('div');
                colorBox.style.width = '12px';
                colorBox.style.height = '12px';
                colorBox.style.backgroundColor = color;
                colorBox.style.marginRight = '5px';

                const nameSpan = document.createElement('span');
                nameSpan.textContent = name;

                legendItem.appendChild(colorBox);
                legendItem.appendChild(nameSpan);
                legendRef.current.appendChild(legendItem);
            }
        } catch (error) {
            console.error('Error adding series with markers:', error);
        }
    };

    const fetchData = async () => {
        setLoading(true);
        setError(null);

        try {
            const response = await axios.get(fetchUrl(tickers, hoursLookback), {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                }
            });

            if (response.status !== 200) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = response.data;

            if (!Array.isArray(data)) {
                throw new Error('Received data is not an array');
            }

            const tickerData: { [key: string]: { x: any[], y: any[] } } = {};

            data.forEach((item: any) => {
                if (!item) {
                    console.error('Encountered null or undefined item in data');
                    return;
                }

                const { ticker, market_ticker, created_time, snapshot_time, mm_position, price_estimate } = item;

                const tickerKey = ticker || market_ticker;
                const timeKey = created_time || snapshot_time;
                const valueKey = mm_position || price_estimate;

                if (!tickerKey || !timeKey || valueKey === undefined) {
                    console.error('Invalid item data:', item);
                    return;
                }

                if (!tickerData[tickerKey]) {
                    tickerData[tickerKey] = { x: [], y: [] };
                }
                tickerData[tickerKey].x.push(timeKey);
                tickerData[tickerKey].y.push(valueKey);
            });

            if (useLightweightCharts && chartRef.current) {
                // Safely remove existing series
                seriesRef.current = seriesRef.current.filter(series => {
                    try {
                        if (chartRef.current) {
                            chartRef.current.removeSeries(series);
                        }
                        return false;
                    } catch (e) {
                        console.error('Error removing series:', e);
                        return true;
                    }
                });

                // Clear legend
                if (legendRef.current) {
                    legendRef.current.innerHTML = '';
                }

                Object.keys(tickerData).forEach((ticker, index) => {
                    const seriesData: LineData<Time>[] = tickerData[ticker].x.map((x, i) => {
                        const time = new Date(x).getTime() / 1000;
                        const value = parseFloat(tickerData[ticker].y[i]);
                        if (isNaN(time) || isNaN(value)) {
                            console.error('Invalid data point:', { x, y: tickerData[ticker].y[i] });
                            return null;
                        }
                        return { time: time as Time, value: value };
                    }).filter((point): point is LineData<Time> => point !== null);

                    if (chartRef.current) {
                        addSeriesWithMarkers(chartRef.current, ticker, seriesData, getColorForIndex(index));
                    }
                });

                // Fit content
                chartRef.current.timeScale().fitContent();
            } else {
                const traces = Object.keys(tickerData).map((ticker) => ({
                    type: 'scatter',
                    mode: 'lines+markers',
                    name: ticker,
                    x: tickerData[ticker].x,
                    y: tickerData[ticker].y,
                }));

                setPlotData({
                    data: traces,
                    layout: {
                        title,
                        xaxis: { title: xaxisTitle },
                        yaxis: { title: yaxisTitle },
                    },
                });
            }

            setShowGraph(true);
        } catch (e: any) {
            console.error('Error fetching data:', e);
            setError(e.message || 'An error occurred while fetching data');
            setShowGraph(false);
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        fetchData();
    };

    const toggleMinimize = () => {
        setIsMinimized(!isMinimized);
    };

    const handleChartTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newUseLightweightCharts = event.target.checked;
        setUseLightweightCharts(newUseLightweightCharts);

        if (newUseLightweightCharts) {
            // Switching to Lightweight Charts
            setShowGraph(true);
        } else {
            // Switching to Plotly
            if (chartRef.current) {
                chartRef.current.remove();
                chartRef.current = null;
            }
            setShowGraph(true);
        }

        // Clear any existing errors
        setError(null);
    };

    const getColorForIndex = (index: number) => {
        const colors = ['#2196f3', '#f44336', '#4caf50', '#ff9800', '#9c27b0', '#795548'];
        return colors[index % colors.length];
    };

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={3}>
                        <TextField
                            label="Lookback (hours)"
                            type="number"
                            value={hoursLookback}
                            onChange={(e) => setHoursLookback(e.target.value)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Button type="submit" variant="contained" color="primary" fullWidth>
                            Fetch Data
                        </Button>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={useLightweightCharts}
                                    onChange={handleChartTypeChange}
                                    color="primary"
                                />
                            }
                            label="Use Lightweight Charts"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <IconButton onClick={toggleMinimize}>
                            {isMinimized ? <ExpandMore /> : <ExpandLess />}
                        </IconButton>
                    </Grid>
                </Grid>
            </form>

            {loading && <CircularProgress />}

            {error && <Alert severity="error">{error}</Alert>}

            {!isMinimized && (
                <>
                    <div ref={chartContainerRef} style={{ width: '100%', height: '400px' }}>
                        {showGraph && !useLightweightCharts && (
                            <Plot
                                data={plotData.data}
                                layout={plotData.layout}
                                style={{ width: '100%', height: '100%' }}
                            />
                        )}
                    </div>
                    {useLightweightCharts && (
                        <div ref={legendRef} style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }} />
                    )}
                </>
            )}
        </div>
    );
};

export default GraphContainer;
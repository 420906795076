import React, { useState } from 'react';
import Plot from 'react-plotly.js';
import { Box, FormControlLabel, Checkbox } from '@mui/material';

const PriceAndPositionGraph = ({ positionData, priceData, tickers }) => {
    const [showPrice, setShowPrice] = useState(true);
    const [showPosition, setShowPosition] = useState(true);

    if (!positionData) positionData = [];
    if (!priceData) priceData = [];

    const plotData = tickers.flatMap((ticker) => [
        showPosition && {
            type: 'scatter',
            mode: 'lines+markers',
            name: `${ticker} Position`,
            x: positionData.filter((d) => d.ticker === ticker).map((d) => d.created_time),
            y: positionData.filter((d) => d.ticker === ticker).map((d) => d.mm_position),
            yaxis: 'y1',
        },
        showPrice && {
            type: 'scatter',
            mode: 'lines+markers',
            name: `${ticker} Price`,
            x: priceData
                .filter((d) => d.market_ticker === ticker)
                .map((d) => new Date(d.snapshot_time).toISOString()),
            y: priceData
                .filter((d) => d.market_ticker === ticker)
                .map((d) => d.price_estimate),
            yaxis: 'y2',
        },
    ]).filter(Boolean);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                height: '60vh',
                width: '60vw',
                padding: 2,
                boxSizing: 'border-box',
            }}
        >
            <Box sx={{ width: '90%', height: '90%' }}>
                <Plot
                    data={plotData}
                    layout={{
                        title: 'Position and Price History',
                        xaxis: { title: 'Time', type: 'date' },
                        yaxis: { title: 'MM Position', side: 'left' },
                        yaxis2: { title: 'Price Estimate', overlaying: 'y', side: 'right' },
                        margin: { t: 50, b: 50, l: 50, r: 150 },
                        legend: {
                            x: 1.05,
                            y: 1,
                            orientation: 'v',
                        },
                    }}
                    useResizeHandler
                    style={{ width: '100%', height: '100%' }}
                />
            </Box>

            <Box sx={{ display: 'flex', gap: 2, marginBottom: 2 }}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={showPosition}
                            onChange={() => setShowPosition((prev) => !prev)}
                        />
                    }
                    label="Show Position"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={showPrice}
                            onChange={() => setShowPrice((prev) => !prev)}
                        />
                    }
                    label="Show Price"
                />
            </Box>
        </Box>
    );
};

export default PriceAndPositionGraph;

import React from 'react';
import { Grid, Typography, Paper, Box } from '@mui/material';
import TickerPriceHistoryPlot from './price_history_graph';
import PositionHistoryGraph from './position_history_graph';

const StackedGraphs: React.FC<{ tickers?: string[] }> = ({ tickers }) => {
    return (
        <Grid container spacing={4} sx={{ marginTop: 2 }}>
            <Grid item xs={12}>
                <Paper elevation={3} sx={{ padding: 2 }}>
                    <Typography variant="h6" align="center" gutterBottom>
                        Historical Price per Market Ticker
                    </Typography>
                    <Box sx={{ padding: 2 }}>
                        <TickerPriceHistoryPlot tickers={tickers} />
                    </Box>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Paper elevation={3} sx={{ padding: 2 }}>
                    <Typography variant="h6" align="center" gutterBottom>
                        Position History
                    </Typography>
                    <Box sx={{ padding: 2 }}>
                        <PositionHistoryGraph tickers={tickers} />
                    </Box>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default StackedGraphs;
